import React from "react";
import styled from "styled-components";
import {useNavigate} from 'react-router-dom';


export const ListingThumbnail = styled.img`
    aspect-ratio: 1/1;
    border-radius: 8px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
`

const HoverWrapper = styled.div`
    cursor: pointer;
    @media screen and (min-width: 768px) {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }
`

const Report = ({thumbnailUrl, reportId, openInNewTab = false}) => {
    let navigate = useNavigate();

    function handleClick() {
        if (openInNewTab) {
            window.open(`/report/${reportId}`, '_blank');
            return
        }

        navigate(`/report/${reportId}`);
    }

    return (
        <HoverWrapper onClick={handleClick}>
            {thumbnailUrl && <ListingThumbnail src={thumbnailUrl}/>}
        </HoverWrapper>
    )
}

export default Report
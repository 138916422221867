import * as React from 'react';
import {useEffect, useState} from 'react';
import {t} from "i18next";
import styled from "styled-components";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid";
import colors from "../../assets/styles/colors";
import TextField from "@mui/material/TextField";
import {toast} from "react-toastify";
import miscService from "../../services/api/misc/miscService";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import reportService from "../../services/api/report/reportService";
import mediaService from "../../services/api/media/mediaService";
import ImageUpload from "../../components/common/UploadImage/ImageUpload";
import BreedSelectorDialog from "../../components/common/Breeds/BreedSelectorDialog";
import LokiPrimaryButton from "../../components/common/Buttons/LokiPrimary";
import Container from "@mui/material/Container";
import NavBar from "../../components/common/NavigationBar/NavBar";
import AutoBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs";


const SectionHeader = styled.h3`
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1rem 0 0;
`

const Selector = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0.9rem;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    border-radius: 4px;
    background: ${colors.searchBackground};
`

const BreedSelector = styled.div`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0.9rem;
    width: 100%;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
    border-radius: 4px;
    background: ${colors.searchBackground};
`

const PropertyTitle = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 6px;
`

const RedOutlineWrapper = styled.div`
    ${({enabled}) => enabled && `
    outline: 1px solid red;
    border-radius: 4px;
    padding:6px;
    margin-bottom:6px;
  `}
`;


export default function ReportCreatePage() {
    let navigate = useNavigate()

    const [openBreedSelector, setOpenBreedSelector] = useState(false);
    const [misc, setMisc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [breedTitle, setBreedTitle] = useState("");
    //TODO: Fix images like in luigi
    const [images, setImages] = useState([]);

    const [values, setValues] = useState({
        breedId: null,
        sizeId: null,
        color: "",
        description: "",
        type: "",
        contactDetails: "",
        address: ""
    });

    const [errors, setErrors] = useState({
        breedId: null,
        sizeId: null,
        color: "",
        description: "",
        type: "",
        contactDetails: "",
        images: ""
    });

    const styleWrapContent = {
        bgcolor: 'background.paper',
        boxShadow: 0,
        borderRadius: '4px',
        p: 4,
    };

    useEffect(() => {

        const fetchMisc = async () => {
            try {
                const miscResponse = await miscService.getMisc();
                setMisc(miscResponse)

                setIsLoading(false);
            } catch (err) {
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchMisc();
    }, []);

    if (isUploading) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <LokiLoader/>
                <h3>{t('uploading_report_title')}</h3>
                <p style={{margin: 0}}>{t('uploading_report_subtitle')}</p>
            </div>
        )
    }

    if (isLoading) {
        return <LokiLoader/>
    }

    const handleChange = (event) => {
        const {name, value} = event.target;

        const newValues = {...values, [name]: value};

        setValues(newValues);
    };


    const onCreateReport = async () => {
        if (!validate()) {
            return
        }
        setIsUploading(true)

        try {
            const mediaResponse = await uploadImages()

            const requestBody = {};
            requestBody.breedId = values.breedId
            requestBody.color = values.color
            requestBody.sizeId = values.sizeId
            requestBody.description = values.description
            requestBody.contactDetails = values.contactDetails
            requestBody.type = values.type
            requestBody.images = mediaResponse.map((media) => media.url)
            requestBody.thumbnails = mediaResponse.map((media) => media.compressedUrl)

            await reportService.createReport(requestBody)

            toast.success(t('report_create_success'))
            setIsUploading(false);
            navigate('/profile', {replace: true})
        } catch (e) {
            toast.error(t(e.code || 'general_error'))
            setIsUploading(false);
        }
    }

    const uploadImages = async () => {
        const imageUrls = []
        for (let i = 0; i < images.length; i++) {
            const result = await mediaService.uploadFile(images[i], "reports", true)
            imageUrls.push(result)
        }

        return imageUrls;
    };

    const validate = () => {
        let tempErrors = {...errors};
        tempErrors.images = images.length > 0 ? '' : t('error_images_mandatory');
        tempErrors.description = values.description ? '' : t('error_field_mandatory');
        tempErrors.color = values.color ? '' : t('error_field_mandatory');
        tempErrors.sizeId = values.sizeId ? '' : t('error_field_mandatory');
        tempErrors.contactDetails = values.contactDetails ? '' : t('error_field_mandatory');
        tempErrors.breedId = values.breedId ? '' : t('error_field_mandatory');
        tempErrors.sizeId = values.sizeId ? '' : t('error_field_mandatory');
        tempErrors.type = values.type ? '' : t('error_field_mandatory');

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };


    return (
        <div>
            <NavBar/>
            <div style={{background: colors.secondaryBackground}}>
                <br/>
                <Container maxWidth="md">
                    <AutoBreadcrumbs/>
                    <br/>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} xl={12}>
                            <RedOutlineWrapper enabled={!!errors.images}>
                                <ImageUpload
                                    setImages={setImages}
                                />
                            </RedOutlineWrapper>
                            <br/>
                            <SectionHeader style={{
                                marginBottom: '1.5rem',
                                marginTop: '2rem'
                            }}>{t('section_new_report')}</SectionHeader>
                            <Box sx={styleWrapContent}>

                                <RedOutlineWrapper enabled={!!errors.type}>
                                    <PropertyTitle key={'type'}>{t('report_type')}</PropertyTitle>
                                    <Selector value={values.type} onChange={(event) => {
                                        const newValues = {...values, type: event.target.value}
                                        setValues(newValues);
                                    }}>
                                        <option value="">{t('select_report_type')}</option>
                                        <option value="Lost">{t('report_type_lost')}</option>
                                        <option value="Found">{t('report_type_found')}</option>
                                    </Selector>
                                </RedOutlineWrapper>

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    sx={{background: `${colors.backgroundColor}`, borderRadius: 1}}
                                    id="color"
                                    label={t('pet_color')}
                                    name="color"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                    value={values.color}
                                    onChange={handleChange}
                                    error={!!errors.color}
                                    helperText={errors.color}
                                />

                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="description"
                                    label={t('report_description')}
                                    name="description"
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    sx={{
                                        background: `${colors.backgroundColor}`,
                                        borderRadius: 1,
                                    }}
                                    value={values.description}
                                    onChange={handleChange}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                />

                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 400,
                                    }}
                                    id="contactDetails"
                                    label={t('report_contact_details')}
                                    name="contactDetails"
                                    multiline
                                    rows={4}
                                    sx={{
                                        background: `${colors.backgroundColor}`,
                                        borderRadius: 1,
                                    }}
                                    value={values.contactDetails}
                                    onChange={handleChange}
                                    error={!!errors.contactDetails}
                                    helperText={errors.contactDetails}
                                />

                                <RedOutlineWrapper enabled={!!errors.breedId}>
                                    <PropertyTitle key={t('breed')}>{t('breed')}</PropertyTitle>
                                    <BreedSelector value={values.breedId} onClick={() => setOpenBreedSelector(true)}>
                                        {breedTitle || t('select_breed')}
                                    </BreedSelector>
                                </RedOutlineWrapper>

                                <RedOutlineWrapper enabled={!!errors.sizeId}>
                                    <PropertyTitle key={t('size')}>{t('size')}</PropertyTitle>
                                    <Selector value={values.sizeId} onChange={(event) => {
                                        const newSizeId = event.target.value; // This is a string.
                                        if (newSizeId === "-1") {
                                            const newValues = {...values, sizeId: null}
                                            setValues(newValues);
                                            return
                                        }
                                        const newSizeIdInt = parseInt(newSizeId, 10);
                                        if (!isNaN(newSizeIdInt)) {
                                            const newValues = {...values, sizeId: newSizeIdInt}
                                            setValues(newValues);
                                        }
                                    }}>
                                        <option value="-1">{t('select_size')}</option>
                                        {misc.sizes.map((size) =>
                                            <option key={size.id} value={size.id}>{t(size.localizationKey)}</option>
                                        )}
                                    </Selector>
                                </RedOutlineWrapper>
                                <br/>
                                <LokiPrimaryButton
                                    style={{marginTop: 16}}
                                    onClick={onCreateReport}
                                >
                                    {t('action_create')}
                                </LokiPrimaryButton>
                                <br/>
                            </Box>
                        </Grid>
                    </Grid>
                    <BreedSelectorDialog open={openBreedSelector} misc={misc}
                                         handleClose={() => setOpenBreedSelector(false)}
                                         onBreedSelected={(breed) => {
                                             const newValues = {...values, breedId: breed.id}
                                             setValues(newValues)
                                             setBreedTitle(`${t(breed.localizationKey)}, ${t(breed.species.localizationKey)}`)
                                         }}/>
                    <br/><br/><br/>

                </Container>
            </div>
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import Navbar from "../../components/common/NavigationBar/NavBar";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../assets/styles/colors";
import {Attachment} from "@mui/icons-material";
import {getFileFromFirebase} from "../../services/firebase/firebaseService";
import legalService from "../../services/api/legal/legalService";
import useDebouncedLoading from "../../hooks/useDebouncedLoading";
import {Fade, Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

const AttachmentTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.primaryColor};
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
`

export default function LegalCompliancePage() {
    const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);
    const [legalRecords, setLegalRecords] = useState([])

    const mapAttachmentsWithRecord = async (records) => {
        return Promise.all(records.map(async (record) => {
            const attachments = record.attachments || [];
            const attachmentsData = await Promise.all(attachments.map(async (attachmentUrl) => {
                const attachment = await getFileFromFirebase(attachmentUrl);
                return attachment; // Assuming this returns an object with `downloadUrl` and `metadata`
            }));
            return {...record, attachmentsData};
        }));
    };

    /* eslint-disable */
    useEffect(() => {
        const fetchLegalRecords = async () => {
            setIsLoading(true)
            const records = await legalService.getLegalAttachments();
            const recordsWithAttachments = await mapAttachmentsWithRecord(records);
            setLegalRecords(recordsWithAttachments);

            setIsLoading(false)
        }

        fetchLegalRecords()
    }, [setIsLoading]);
    /* eslint-enable */

    if (showLoadingUI) {
        return (
            <>
                <Navbar/>
                <Container maxWidth="lg">
                    <br/>
                    <h1 style={{textAlign: 'center', fontWeight: '600'}}>{t('legal_title')}</h1>
                    <p style={{textAlign: 'center'}}>{t('legal_subtitle')}</p>
                    <br/>
                    <Fade in={true}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                            <Skeleton variant="rectangular" height={80} style={{marginBottom: 16, borderRadius: 4}}/>
                            <Skeleton variant="rectangular" height={80} style={{marginBottom: 16, borderRadius: 4}}/>
                            <Skeleton variant="rectangular" height={80} style={{borderRadius: 4}}/>
                        </div>
                    </Fade>
                </Container>
            </>
        )
    }

    const getAttachment = (attachment) => {
        return (
            <Item key={attachment.downloadUrl} onClick={() => window.open(attachment.downloadUrl, '_blank')}>
                <Attachment sx={{color: `${colors.divider}`}}/>
                <AttachmentTitle>{attachment.metadata.name}</AttachmentTitle>
            </Item>
        );
    }

    const getContent = (record) => {
        switch (record.type) {
            case "COOKIE_POLICY":
                return record.attachmentsData.map((attachment, index) => (
                    getAttachment(attachment)
                ));
            case "TC":
                return record.attachmentsData.map((attachment, index) => (
                    getAttachment(attachment)
                ));
            case "PRIVACY_POLICY":
                return record.attachmentsData.map((attachment, index) => (
                    getAttachment(attachment)
                ));
            default:
                return <div/>;
        }
    }


    return (
        <>
            <Navbar/>
            <Container maxWidth="md">
                <br/>
                <h1 style={{textAlign: 'center', fontWeight: '600'}}>{t('legal_title')}</h1>
                <p style={{textAlign: 'center'}}>{t('legal_subtitle')}</p>
                <br/>
                {isLoading ? <div/> :
                    <Fade in={true}>
                        <div>
                            {legalRecords.map((record) => (
                                <Card key={record.type}
                                      sx={{mb: 2, position: 'relative', display: 'flex', flexDirection: 'column'}}>
                                    <CardContent sx={{flex: '1 0 auto'}}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {t(record.localizedTitle)}
                                        </Typography>
                                        {getContent(record)}
                                    </CardContent>
                                    <Box sx={{position: 'absolute', top: 8, right: 16}}>
                                        <Typography
                                            variant="body1">{dayjs(record.createdAt.toDate()).format('YYYY-MM-DD')}</Typography>
                                    </Box>
                                </Card>
                            ))}
                        </div>
                    </Fade>}
            </Container>
        </>
    );
};

import Link from "@mui/material/Link";
import {ChevronLeft} from "@mui/icons-material";
import {t} from "i18next";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function BackLink() {
    let navigate = useNavigate()
    const onBackClick = () => {
        navigate(-1, {replace: true});
    };

    return (
        <Link onClick={onBackClick} variant="body" underline="none"
              sx={{cursor: "pointer"}}>
            <ChevronLeft/>
            {t('back')}
        </Link>
    );
}
import {useRef, useState} from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import {t} from "i18next";
import {toast} from "react-toastify";
import LokiSecondaryOutlinedButton from "../Buttons/LokiSecondaryOutlinedButton";

const Thumbnail = styled.img`
    aspect-ratio: 1/1;
    border-radius: 8px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
`;

const ImageContainer = styled.div`
    position: relative;

    &:hover p {
        display: flex;
    }
`;

const DeleteText = styled.p`
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem;
    border-radius: 8px;
    margin: 0;
    cursor: pointer;
`;

export const HiddenFileInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

export default function ImageUpload({setImages}) {
    const fileInputRef = useRef(null);
    const [imagePreviews, setImagePreviews] = useState([]);

    const handleMultipleImage = (event) => {
        const newFiles = [...event.target.files];
        setImages((currentImages) => {
            const potentialUpdate = [...currentImages, ...newFiles];
            if (potentialUpdate.length > 4) {
                toast.error(t('error_photo_limit'));
                return currentImages;
            }
            const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
            setImagePreviews((currentPreviews) => [...currentPreviews, ...newPreviews]);
            return potentialUpdate;
        });
    };

    const handleDeleteImage = (index) => {
        setImages((currentImages) => {
            const newImages = [...currentImages];
            newImages.splice(index, 1);
            const newImagePreviews = [...imagePreviews];
            newImagePreviews.splice(index, 1);
            setImagePreviews(newImagePreviews);
            return newImages;
        });
    };

    return (
        <>
            <div className="upload">
                <h4>{t('images')}</h4>

                <Grid container spacing={2}>
                    {imagePreviews.map((preview, index) => (
                        <Grid item key={index} xs={12} md={6} lg={3} xl={3}>
                            <ImageContainer>
                                <Thumbnail src={preview} alt={`Peržiura ${index}`}/>
                                <DeleteText onClick={() => handleDeleteImage(index)}>
                                    {t('delete_image')}
                                </DeleteText>
                            </ImageContainer>
                        </Grid>
                    ))}
                </Grid>

                <form>
                    <HiddenFileInput
                        type="file"
                        multiple
                        accept=".png, .jpg, .jpeg"
                        id="file"
                        onChange={handleMultipleImage}
                        ref={fileInputRef}
                    />
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6} xl={6}>
                            <LokiSecondaryOutlinedButton
                                type="button"
                                onClick={() => fileInputRef.current.click()}>
                                {t('choose_images')}
                            </LokiSecondaryOutlinedButton>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}

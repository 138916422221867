import React from "react";
import {HashLoader} from "react-spinners";
import colors from "../../../assets/styles/colors";
import styled from "styled-components";

const Centered = styled.div`
    display: grid;
    place-items: center;
    margin-top: 10rem;
`

const LokiLoader = () => {
  return (
      <Centered>
          <HashLoader color={colors.primaryColor}/>
      </Centered>
  )
}
export default LokiLoader
import React, {createContext, useEffect, useState} from 'react';
import {auth, onAuthStateChanged} from "../services/firebase/firebaseService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        token: null,
        isLoading: true,
        isVerifiedWithBackend: false
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthState({
                    isAuthenticated: true,
                    user: user,
                    token: null,
                    isLoading: false
                });
            } else {
                setAuthState({
                    isAuthenticated: false,
                    user: null,
                    token: null,
                    isLoading: false
                });
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ authState, setAuthState }}>
            {children}
        </AuthContext.Provider>
    );
};
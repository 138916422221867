import SearchBar from "./SearchBar";
import {t} from "i18next";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse, Fade} from "@mui/material";
import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";


const FilterExpandingWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 6px;
    align-items: center;
    justify-content: space-between;
`

const FilterTitle = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
`

const FilterHeading = styled.h2`
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`

const Selector = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0.7rem;
    width: 100%;
    text-align: left;
    border-radius: 8px;
    margin-bottom: 6px;
    background: ${colors.searchBackground};
`

const SearchButton = styled.button`
    border-radius: 8px;
    width: 100%;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px 16px;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            background: ${colors.primaryColor};
        }
    }
`

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    margin-top: 16px;
    gap: 10px;
`
/* eslint-disable eqeqeq */
const Filter = ({filterRanges, filters, setFilters, applyFilter, onSearchClick}) => {
    const [filterExpanded, setFilterExpanded] = useState(window.innerWidth > 900);

    function toggleFilterExpand() {
        setFilterExpanded(!filterExpanded);
    }

    const handleSpeciesSelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, species: selection, breed: -1};
        setFilters(newValues)
    }

    const handleBreedSelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, breed: selection};
        setFilters(newValues)
    }

    const handleSizeSelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, size: selection};
        setFilters(newValues)
    }

    const handleAgeSelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, ageTo: selection};
        setFilters(newValues)
    }

    const handleGenderSelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, gender: selection};
        setFilters(newValues)
    }

    const handleSearchQueryChange = (event) => {
        const query = event.target.value
        const newValues = {...filters, searchQuery: query};
        setFilters(newValues)
    }


    return (
        <Fade in={true} timeout={300}>
            <FilterWrapper>
                <SearchBar value={filters.searchQuery} onChange={handleSearchQueryChange}/>
                <SearchButton onClick={onSearchClick}>{t('search')}</SearchButton>

                <FilterExpandingWrapper style={{marginTop: "1.2rem"}}>
                    <FilterHeading onClick={toggleFilterExpand}>{t('filters')}</FilterHeading>
                    {filterExpanded ?
                        (<ExpandLess
                            onClick={toggleFilterExpand}
                            aria-expanded={filterExpanded}
                            aria-label="show less"
                            sx={{marginBottom: 1, cursor: 'pointer'}}
                        />) :
                        (<ExpandMore
                            onClick={toggleFilterExpand}
                            aria-expanded={filterExpanded}
                            aria-label="show more"
                            sx={{marginBottom: 1, cursor: 'pointer'}}
                        />)}
                </FilterExpandingWrapper>

                <Collapse sx={{width: "100%"}} in={filterExpanded} timeout="auto" unmountOnExit>
                    <FilterTitle>{t('filter_breed')}</FilterTitle>
                    <Selector onChange={handleSpeciesSelect} value={filters.species}>
                        <option value={-1} key={-1}>{t('any_species')}</option>
                        {filterRanges.species.map((species) => <option
                            value={species.id} key={species.id}>{t(species.localizationKey)}</option>)}
                    </Selector>

                    {filters.species != -1 ? (
                        <Selector value={filters.breed} onChange={handleBreedSelect}>
                            <option value={-1}>{t('any_breed')}</option>
                            {filterRanges.breeds.filter((breed) => breed.speciesId == filters.species).map((breed) =>
                                <option
                                    value={breed.id} key={breed.id}>{t(breed.localizationKey)}</option>)}
                        </Selector>
                    ) : (<div></div>)
                    }

                    <FilterTitle>{t('filter_size')}</FilterTitle>
                    <Selector value={filters.size} onChange={handleSizeSelect}>
                        <option value={-1} key={-1}>{t('any_size')}</option>
                        {filterRanges.sizes.map((size) => <option value={size.id}
                                                                  key={size.id}>{t(size.localizationKey)}</option>)}
                    </Selector>

                    <FilterTitle>{t('filter_age')}</FilterTitle>
                    <Selector value={filters.ageTo} onChange={handleAgeSelect}>
                        <option value={-1}>{t('any_age')}</option>
                        <option value={1}>{t('age_less_than_1')}</option>
                        <option value={2}>{t('age_less_than_2')}</option>
                        <option value={5}>{t('age_less_than_5')}</option>
                        <option value={10}>{t('age_less_than_10')}</option>
                    </Selector>

                    <FilterTitle>{t('filter_gender')}</FilterTitle>
                    <Selector value={filters.gender} onChange={handleGenderSelect}>
                        <option value={-1}>{t('any_gender')}</option>
                        <option value="Male">{t('Male')}</option>
                        <option value="Female">{t('Female')}</option>
                    </Selector>
                    <SearchButton style={{marginTop: '0.5rem'}} onClick={applyFilter}>{t('apply_filter')}</SearchButton>
                </Collapse>
            </FilterWrapper>
        </Fade>
    );
}
/* eslint-enable eqeqeq */
export default Filter
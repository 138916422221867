import React from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";
import Grid from "@mui/material/Grid";

const SearchField = styled.input`
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 0.8rem 16px;
    background: ${colors.searchBackground};

    @media (max-width: 768px) {
        padding: 1rem 16px;
    }
`

const SearchWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const SearchButton = styled.button`
    border-radius: 8px;
    width: 100%;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px 16px;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            background: ${colors.primaryColor};
        }
    }
`

const SearchBarWithButton = ({handleChange, onSearchClick}) => {
    return (
        <SearchWrapper>
            <Grid container spacing={2} sx={{paddingLeft:"1rem", paddingRight:"1rem", display:"flex", justifyContent:"center"}}>
                <Grid item xs={0} sm={0} md={3} lg={4}>
                </Grid>
                <Grid item xs={12} sm={8} md={4} lg={3}>
                    <SearchField onChange={handleChange} placeholder={t('search_hint')}/>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={1}>
                    <SearchButton onClick={onSearchClick}>{t('search')}</SearchButton>
                </Grid>
                <Grid item xs={0} sm={0} md={3} lg={4}>
                </Grid>
            </Grid>
        </SearchWrapper>
    );
}

export default SearchBarWithButton
import {Skeleton} from "@mui/material";
import React from "react";
import styled from "styled-components";

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 16px;
    margin-top: 16px;
    gap: 10px;
`
/* eslint-disable eqeqeq */
const FilterSkeleton = ({filterCount = 1}) => {
    return (
        <FilterWrapper>
            <div style={{width: '100%', marginBottom: '1rem'}}>
                <Skeleton variant="rectangle" width="100%" height={50} style={{marginBottom: 0, borderRadius: 4}}/>
                <Skeleton variant="rectangle" width="100%" height={45} style={{marginTop: '8px', borderRadius: 4}}/>
            </div>
            <Skeleton width='30%' height={40}/>
            <div style={{width: '100%', marginBottom: '1rem'}}>
                {Array.from(new Array(filterCount)).map((_, index) => (
                    <div>
                        <Skeleton width='30%' height={40}/>
                        <Skeleton variant="rectangle" width="100%" height={45}
                                  style={{marginBottom: 0, borderRadius: 4}}/>
                    </div>
                ))}
                <Skeleton variant="rectangle" width="100%" height={45} style={{marginTop: '12px', borderRadius: 4}}/>
            </div>
        </FilterWrapper>
    )
        ;
}
/* eslint-enable eqeqeq */
export default FilterSkeleton
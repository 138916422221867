import apiService from '../baseApiService';

const searchService = {
    search: async (filter) => {
        if (typeof filter === 'undefined') {
            const url = `/v1/search?page=0&limit=10`;
            return await apiService.get(url);
        }

        const queryParams = new URLSearchParams();
        /* eslint-disable eqeqeq */
        const {
            searchQuery = '',
            breed = -1,
            species = -1,
            size = -1,
            ageTo = -1,
            gender = -1,
            sort = '',
            page = 0,
            limit = 10
        } = filter;

        if (searchQuery) queryParams.append('searchQuery', searchQuery);
        if (breed != -1) queryParams.append('breed', breed.toString());
        if (species != -1) queryParams.append('species', species.toString());
        if (size != -1) queryParams.append('size', size.toString());
        if (ageTo != -1) queryParams.append('ageTo', ageTo.toString());
        if (gender != -1) queryParams.append('gender', gender.toString());
        if (sort) queryParams.append('sort', sort);
        if (page >= 0) queryParams.append('page', page.toString());
        if (limit) queryParams.append('limit', limit.toString());
        /* eslint-enable eqeqeq */
        const url = `/v1/search?${queryParams.toString()}`;
        return await apiService.get(url);
    },

    searchShelters: async (filter) => {
        if (typeof filter === 'undefined') {
            const url = `/v1/search/shelters?page=0&limit=10`;
            return await apiService.get(url);
        }

        const queryParams = new URLSearchParams();
        /* eslint-disable eqeqeq */
        const {searchQuery = '', cityId = -1, sort = '', page = 0, limit = 10} = filter;
        if (searchQuery) queryParams.append('searchQuery', searchQuery);
        if (cityId != -1) queryParams.append('cityId', cityId.toString());
        if (sort) queryParams.append('sort', sort);
        if (page >= 0) queryParams.append('page', page.toString());
        if (limit) queryParams.append('limit', limit.toString());
        /* eslint-enable eqeqeq */
        const url = `/v1/search/shelters?${queryParams.toString()}`;
        return await apiService.get(url);
    },
};

export default searchService;

import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";

const Avatar = styled.img`
    border-radius: 100rem;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;
`

const DetailsWrapper = styled.section`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 10px;
`

const DisplayName = styled.h4`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 600;
    line-height: 1;
`

const ProfilePictureWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    @media (max-width: 599px) {
        justify-content: center;
        padding-right: 10px;
    }
`

const BioContent = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 500;
    text-align: left;
    line-height: 1.3;
    @media (max-width: 599px) {
        margin: 0 0 8px;
    }
`

const ProfileActionButton = styled.button`
    padding: 8px 30px;
    background-color: ${colors.secondaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 599px) {
        flex: unset;
        padding: 10px 10px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 599px) {
        flex-direction: column;
        width: 100%;
        gap: 8px;
    }
`

export default function MyProfileHeader ({avatar, displayName, bio, onEditClick}) {
    return (
        <Grid container spacing={2}
              sx={{paddingLeft: "1rem", paddingRight: "1rem", display: "flex", justifyContent: "center"}}>
            <Grid item xs={4} sm={3} lg={2}>
                <ProfilePictureWrapper>
                    <Avatar src={avatar} alt="Avatar"/>
                </ProfilePictureWrapper>
            </Grid>
            <Grid item xs={8} sm={9} lg={10}>
                <DetailsWrapper>
                    <DisplayName>{displayName}</DisplayName>
                    <ButtonsWrapper>
                        <ProfileActionButton onClick={onEditClick}>
                            {t('edit_profile')}
                        </ProfileActionButton>
                    </ButtonsWrapper>
                </DetailsWrapper>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
                <BioContent>{bio}</BioContent>
            </Grid>
        </Grid>
    );
}
import React, {useEffect, useState} from 'react';
import {Alert, Checkbox, FormControlLabel, FormGroup, TextField} from '@mui/material';
import LokiPrimaryButton from "../../components/common/Buttons/LokiPrimary";
import LokiSecondaryOutlinedButton from "../../components/common/Buttons/LokiSecondaryOutlinedButton";
import {useNavigate, useParams} from "react-router-dom";
import listingService from "../../services/api/listing/listingService";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import Listing from "../../components/features/Listing/Listing";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Container from "@mui/material/Container";
import NavBar from "../../components/common/NavigationBar/NavBar";
import colors from "../../assets/styles/colors";
import {t} from "i18next";
import {toast} from "react-toastify";
import Unknown from "../../assets/images/unknown.svg";
import ProfileWidget from "../../components/features/Profile/Profile";
import reservationService from "../../services/api/reservation/reservationService";
import userService from "../../services/api/user/userService";

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Title = styled.h2`
    font-weight: 600;
    font-size: 1.7rem;
    margin: 1rem 0 0;
    color: ${colors.textColor};
`

const Subtitle = styled.p`
    font-weight: 400;
    font-size: 1rem;
    margin: 1rem 0 0;
    color: ${colors.textColor};
`

const SectionHeader = styled.h3`
    font-weight: 600;
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
    color: ${colors.textColor};
`

const SectionHeaderSmall = styled.h3`
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: ${colors.textColor};
`


const HideInDesktop = styled.section`
    margin-top: 1rem;
    @media (min-width: 900px) {
        display: none;
    }
`

const HideInMobileSection = styled.section`
    margin-top: 1rem;
    @media (max-width: 900px) {
        display: none;
    }
`

const Selector = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 1rem;
    width: 100%;
    text-align: left;
    border-radius: 12px;
    margin-bottom: 6px;
    margin-top: 12px;
    background: ${colors.searchBackground};
`

const RedOutlineWrapper = styled.div`
    ${({ enabled }) => enabled && `
    outline: 1px solid red;
    border-radius: 12px;
    padding:6px;
    margin-bottom:6px;
  `}
`;

function SubmitReservationPage() {
    const navigate = useNavigate()
    const { listingId } = useParams();
    const [listing, setListing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [formData, setFormData] = useState({
        fullName: "",
        age: undefined,
        phoneNumber: "",
        household: {
            count: undefined,
            allergiesToAnimals: "",
            minimalAge: undefined,
            residenceType: "",
            renting: false,
            hasYard: false,
        },
        petOwnershipExperience: {
            previousPetsDetails: "",
        },
        lifestyleAndEnvironment: {
            hoursPetWillBeAlone: undefined,
            activityLevel: "",
        },
        additionalInformation: ""
    });

    const [formErrors, setFormErrors] = useState({
        fullName: "",
        age: "",
        phoneNumber: "",
        household: {
            count: "",
            minimalAge: "",
            residenceType: "",
        },
        lifestyleAndEnvironment: {
            hoursPetWillBeAlone: undefined,
            activityLevel: ""
        },
    });

    const validate = () => {
        let errors = {};
        errors.fullName = formData.fullName.trim() ? '' : t('error_field_mandatory');
        errors.age = formData.age > 17 ? '' : t('error_field_mandatory');
        errors.phoneNumber = formData.phoneNumber.startsWith('+370') ? '' : t('phone_number_must_start_with_plus');
        errors.household = {
            count: formData.household.count > 0 ? '' : t('error_field_mandatory'),
            minimalAge: formData.household.minimalAge > 0 ? '' : t('error_field_mandatory'),
            residenceType: formData.household.residenceType ? '' : t('error_field_mandatory')
        };
        errors.lifestyleAndEnvironment = {
            hoursPetWillBeAlone: formData.lifestyleAndEnvironment.hoursPetWillBeAlone ? '' : t('error_field_mandatory'),
            activityLevel: formData.lifestyleAndEnvironment.activityLevel ? '' : t('error_field_mandatory')
        };

        // Check if any error exists
        const hasErrors = Object.values(errors).some(error => {
            if(typeof error === 'object') {
                return Object.values(error).some(val => val !== '');
            }
            return error !== '';
        });
        setFormErrors(errors);

        return !hasErrors;
    };


    useEffect(() => {
        const fetchListing = async () => {
            try {
                const listingResponse = await listingService.getListing(listingId);
                const userKyc = await userService.getMyKyc()
                if(userKyc) {

                    //TODO: Remove string mappings on numbers
                    const mappedKyc = {
                        fullName: userKyc.fullName,
                        age: `${userKyc.age}`,
                        phoneNumber: `${userKyc.phoneNumber}`,
                        household: {
                            count: `${userKyc.householdCount}`,
                            allergiesToAnimals: userKyc.allergiesToAnimals,
                            minimalAge: `${userKyc.minimalAge}`,
                            residenceType: userKyc.residenceType,
                            renting: userKyc.renting,
                            hasYard: userKyc.hasYard,
                        },
                        petOwnershipExperience: {
                            previousPetsDetails: userKyc.previousPetsDetails,
                        },
                        lifestyleAndEnvironment: {
                            hoursPetWillBeAlone: `${userKyc.hoursPetWillBeAlone}`,
                            activityLevel: userKyc.activityLevel,
                        },
                        additionalInformation: userKyc.additionalInformation
                    }

                    setFormData(mappedKyc)
                }
                setListing(listingResponse);
                setIsLoading(false);
            } catch(err) {
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchListing();
    }, [listingId]);

    if(isLoading) {
        return <LokiLoader />
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        const updateNestedObject = (obj, keys, newValue) => {
            const [firstKey, ...restKeys] = keys;
            if(restKeys.length === 0) {
                return { ...obj, [firstKey]: newValue };
            } else {
                return { ...obj, [firstKey]: updateNestedObject(obj[firstKey], restKeys, newValue) };
            }
        };

        const keys = name.split('.');
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => updateNestedObject(prevState, keys, inputValue));
    };

    const handleSelectorChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updateNestedObject = (obj, keys, newValue) => {
            const [firstKey, ...restKeys] = keys;
            if(restKeys.length === 0) {
                return { ...obj, [firstKey]: newValue };
            } else {
                return { ...obj, [firstKey]: updateNestedObject(obj[firstKey], restKeys, newValue) };
            }
        };

        const keys = name.split('.');
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData(prevState => updateNestedObject(prevState, keys, inputValue));
    };

    const handleSubmit = async (e) => {
        if(!validate()) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        try {
            setIsLoading(true)
            const response = await reservationService.reserve(listingId, JSON.stringify(formData))
            toast.success(t('reservation_success'))
            if(!response.reservationNumber) {
                navigate(`/`, { replace: true })
                return
            }

            navigate(`${response.reservationNumber}/success`, { replace: true })
        } catch(e) {
        } finally {
            setIsLoading(false)
        }
    };

    const styleWrapContent = {
        bgcolor: 'background.paper',
        boxShadow: 0,
        borderRadius: '4px',
        p: 4,
    };

    const getAvatar = () => {
        if(listing.profile.avatarUrl) {
            return listing.profile.avatarUrl
        }
        return Unknown
    }

    return (
        <div>
            <NavBar />
            <Container maxWidth="lg" sx={{ mt: 0 }}>
                <Content>
                    <HideInDesktop>
                        <Title>
                            {t('kyc_title')}
                        </Title>
                        <Subtitle>
                            {t('kyc_subtitle').replaceAll("{petName}", listing.pet.name).replace("{displayName}", listing.profile.displayName)}
                        </Subtitle>
                    </HideInDesktop>
                    <Grid container>
                        <Grid item xs={12}>
                            <HideInDesktop>
                                <div style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                                    <LokiSecondaryOutlinedButton style={{ marginBottom: '1rem' }}
                                        onClick={() => navigate(`/listing/${listing.id}`)}>{t('see_listing')}</LokiSecondaryOutlinedButton>
                                    <LokiSecondaryOutlinedButton
                                        onClick={() => navigate(`/profile/${listing.profile.userId}`)}>{t('see_shelter')}</LokiSecondaryOutlinedButton>
                                </div>
                            </HideInDesktop>
                        </Grid>
                        <Grid item xs={12} md={8} xl={8}>
                            <Box sx={styleWrapContent}>
                                <HideInMobileSection>
                                    <Title>
                                        {t('kyc_title')}
                                    </Title>
                                    <Subtitle>
                                        {t('kyc_subtitle').replaceAll("{petName}", listing.pet.name).replace("{displayName}", listing.profile.displayName)}
                                    </Subtitle>
                                    <br />
                                </HideInMobileSection>
                                <TextField
                                    fullWidth
                                    label={t('full_name')}
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('full_name_helper')}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    error={!!formErrors.fullName}
                                    helperText={formErrors.fullName}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    label={t('phone_number')}
                                    name="phoneNumber"
                                    autoComplete="tel"
                                    inputProps={{
                                        maxLength: 15,
                                    }}
                                    sx={{
                                        background: `${colors.backgroundColor}`,
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    error={!!formErrors.phoneNumber}
                                    helperText={formErrors.phoneNumber}
                                />
                                <TextField
                                    fullWidth
                                    label={t('age')}
                                    name="age"
                                    type="tel"
                                    inputProps={{
                                        maxLength: 2,
                                        onKeyPress: (event) => {
                                            if(!/\d/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    value={formData.age}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('age_helper')}
                                    error={!!formErrors.age}
                                    helperText={formErrors.age}
                                />
                                <SectionHeader>
                                    {t('kyc_section_household')}
                                </SectionHeader>
                                <RedOutlineWrapper enabled={!!formErrors.household.residenceType}>
                                    <Selector
                                        name="household.residenceType"
                                        value={formData.household.residenceType}
                                        onChange={handleInputChange}>
                                        <option label={t('select_residency_type')} value={null}></option>
                                        <option value={"residency_apartment"}>{t('residency_type_apartment')}</option>
                                        <option value={"residency_house"}>{t('residency_type_house')}</option>
                                    </Selector>
                                </RedOutlineWrapper>
                                <TextField
                                    fullWidth
                                    label={t('household_members')}
                                    type="tel"
                                    inputProps={{
                                        maxLength: 2,
                                        onKeyPress: (event) => {
                                            if(!/\d/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    name="household.count"
                                    value={formData.household.count}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('household_members_helper')}
                                    error={!!formErrors.household.count}
                                    helperText={formErrors.household.count}
                                />
                                <TextField
                                    fullWidth
                                    label={t('household_youngest')}
                                    type="tel"
                                    inputProps={{
                                        maxLength: 2,
                                        onKeyPress: (event) => {
                                            if(!/\d/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    name="household.minimalAge"
                                    value={formData.household.minimalAge}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('household_youngest_helper')}
                                    error={!!formErrors.household.minimalAge}
                                    helperText={formErrors.household.minimalAge}
                                />
                                <TextField
                                    fullWidth
                                    label={t('allergies')}
                                    name="household.allergiesToAnimals"
                                    value={formData.household.allergiesToAnimals}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    inputProps={{
                                        maxLength: 200,
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    placeholder={t('allergies_helper')}
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.household.renting}
                                                onChange={handleInputChange}
                                                name="household.renting"
                                            />
                                        }
                                        label={t('landlord_permission')}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.household.hasYard}
                                                onChange={handleInputChange}
                                                name="household.hasYard"
                                            />
                                        }
                                        label={t('yarn_for_pet')}
                                    />
                                </FormGroup>
                                <SectionHeader>
                                    {t('kyc_section_previous_pet_experience')}
                                </SectionHeader>
                                <TextField
                                    fullWidth
                                    label={t('previous_pet_details')}
                                    name="petOwnershipExperience.previousPetsDetails"
                                    value={formData.petOwnershipExperience.previousPetsDetails}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    multiline
                                    inputProps={{
                                        maxLength: 400,
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    rows={4}
                                    placeholder={t('previous_pet_details_helper')}
                                />
                                <SectionHeader>
                                    {t('kyc_section_lifestyle')}
                                </SectionHeader>

                                <RedOutlineWrapper
                                    enabled={!!formErrors.lifestyleAndEnvironment.activityLevel}>
                                    <Selector
                                        name="lifestyleAndEnvironment.activityLevel"
                                        value={formData.lifestyleAndEnvironment.activityLevel}
                                        onChange={handleSelectorChange}>
                                        <option label={t('select_activity_level')} value={null}></option>
                                        <option value={"activity_low"}>{t('activity_level_low')}</option>
                                        <option value={"activity_medium"}>{t('activity_level_medium')}</option>
                                        <option value={"activity_high"}>{t('activity_level_high')}</option>
                                        <option value={"activity_very_high"}>{t('activity_level_very_high')}</option>
                                    </Selector>
                                </RedOutlineWrapper>
                                <TextField
                                    fullWidth
                                    label={t('hours_alone')}
                                    type="tel"
                                    inputProps={{
                                        maxLength: 2,
                                        onKeyPress: (event) => {
                                            if(!/\d/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    name="lifestyleAndEnvironment.hoursPetWillBeAlone"
                                    value={formData.lifestyleAndEnvironment.hoursPetWillBeAlone}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('hours_alone_helper')}
                                    error={!!formErrors.lifestyleAndEnvironment.hoursPetWillBeAlone}
                                    helperText={formErrors.lifestyleAndEnvironment.hoursPetWillBeAlone}
                                />
                                <SectionHeader>
                                    {t('kyc_section_other')}
                                </SectionHeader>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 400,
                                    }}
                                    sx={{
                                        borderRadius: '12px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                        }
                                    }}
                                    label={t('additional_info')}
                                    name="additionalInformation"
                                    value={formData.additionalInformation}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    placeholder={t('additional_info_helper')}
                                />
                                {/*TODO: Modify when Nedas develops cron job to remove kyc*/}
                                <Alert severity="info"
                                    sx={{ marginTop: '4rem' }}>{t('kyc_privacy_disclaimer').replaceAll("{shelterName}", listing.profile.legalDisplayName)}</Alert>
                                <LokiPrimaryButton onClick={handleSubmit}
                                    style={{ marginTop: '1rem', marginBottom: '4rem' }}>
                                    {t('submit_reservation')}
                                </LokiPrimaryButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <HideInMobileSection>
                                <Box sx={styleWrapContent}>
                                    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                        <SectionHeaderSmall>{t('shelter')}</SectionHeaderSmall>
                                        <ProfileWidget avatar={getAvatar()} displayName={listing.profile.displayName}
                                            bio={listing.profile.bio}
                                            onClick={() => navigate(`/profile/${listing.profile.userId}`)} />
                                        <br />
                                        <SectionHeaderSmall>{t('listing')}</SectionHeaderSmall>
                                        <Listing listing={listing} />
                                    </div>
                                </Box>
                            </HideInMobileSection>
                        </Grid>
                    </Grid>
                </Content>
            </Container>
        </div>
    );
}

export default SubmitReservationPage;

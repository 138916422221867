import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import defaultAvatar from '../../../assets/images/unknown.svg';
import {auth} from "../../../services/firebase/firebaseService";

const StyledAvatar = styled.img`
    border-radius: 50%;
    width: 44px;
    height: 44px;
    cursor: pointer;
`;

const UserAvatar = ({onClick}) => {
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        const updateAvatarUrl = () => {
            const url = auth.currentUser?.photoURL || defaultAvatar;
            setAvatarUrl(url);
        };

        updateAvatarUrl();

        return () => {
        };
    }, []);

    return <StyledAvatar src={avatarUrl} alt="" onClick={onClick}/>;
};

export default UserAvatar;
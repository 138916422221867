import React from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";

const SearchField = styled.input`
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 12px 18px;
    background: ${colors.searchBackground};
`

const SearchBar = ({onChange, value, hint = "search_hint"}) => {
    return (
        <SearchField onChange={onChange} value={value} placeholder={t(hint)}/>
    );
}

export default SearchBar
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import "../../../index.css"
import {t} from "i18next";
import TextTransition, {presets} from 'react-text-transition';
import {Fade} from "@mui/material";
import {LinearGradient} from "react-text-gradients";

const BannerWrapper = styled.div`
    text-align: center;
    margin-right: 16px;
    margin-left: 16px;
    padding: 50px 0;
    margin-top: 2rem;
    @media (max-width: 599px) {
        margin-top: 0;
    }
`

const Spacer = styled.div`
    height: 1.5rem;

    @media (max-width: 950px) {
        height: 0;
    }
`

const DesktopWrapper = styled.div`
    font-size: 4rem;
    font-weight: 800;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    @media (max-width: 950px) {
        display: none;
    }
`;

const MobileWrapper = styled.div`
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    @media (min-width: 950px) {
        display: none;
    }
`;

const Paragraph = styled.p`
    font-size: 1.25rem;
    color: #4b5563;
    text-align: center;
    max-width: 48rem;
    margin: auto;
    @media (max-width: 599px) {
        font-size: 1.1rem;
    }
`;

const WORDS = ['feed_rolling_words_1', 'feed_rolling_words_2', 'feed_rolling_words_3', 'feed_rolling_words_4', 'feed_rolling_words_5'];

const Banner = React.memo(() => {
    const [index, setIndex] = useState(1);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIndex((index) => (index + 1) % WORDS.length);
        }, 100);

        const intervalId = setInterval(() => {
            setIndex((index) => (index + 1) % WORDS.length);
        }, 2300);

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, []);


    return (
        <BannerWrapper>
            <Fade in={true}>
                <DesktopWrapper>
                    <LinearGradient
                        style={{fontFamily:"Inter"}}
                        gradient={['to right', '#E8420D ,#EC711D']}
                        fallbackColor="black">
                        {t('feed_header_start')}
                    </LinearGradient>
                    <TextTransition springConfig={presets.default}
                                    style={{display: "flex", alignItems: 'center'}}
                                    inline={true} direction="up"
                                    translateValue="15%">
                        <LinearGradient gradient={['to right', '#EC711D ,#EE982A']} style={{fontFamily: "Inter"}}
                                        fallbackColor="black">
                            {t(WORDS[index % WORDS.length])}
                        </LinearGradient>
                    </TextTransition>
                    <LinearGradient
                        style={{fontFamily:"Inter"}}
                        gradient={['to right', '#EE982A ,#F1C338']}>{t('feed_header_end')}</LinearGradient>
                </DesktopWrapper>
            </Fade>
            <MobileWrapper>
                <LinearGradient gradient={['to right', '#E8420D ,#F1C338']}>{t('feed_header')}</LinearGradient>
            </MobileWrapper>
            <Spacer/>
            <Fade in={true}>
                <Paragraph>{t('feed_subheader')}</Paragraph>
            </Fade>
        </BannerWrapper>
    );
});

export default Banner;

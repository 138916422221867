import apiService from '../baseApiService';

const reportService = {
    getReports: async (from, searchQuery, type, page, limit) => {
        let queryParams = [
            `type=${encodeURIComponent(type || undefined)}`,
            `from=${encodeURIComponent(from || undefined)}`,
            `searchQuery=${encodeURIComponent(searchQuery || undefined)}`,
            `page=${encodeURIComponent(page)}`,
            `limit=${encodeURIComponent(limit)}`
        ];

        queryParams = queryParams.filter(param => !param.includes('undefined'));

        const queryString = `?${queryParams.join('&')}`;
        const url = `/v1/reports${queryString}`;

        // Assuming apiService.get() returns a promise
        return apiService.get(url);
    },
    getFoundReports: async (from) => {
        if (from) {
            return await apiService.get(`/v1/reports?type=Found&from=${from}`);
        }
        return await apiService.get(`/v1/reports?type=Found`);
    },
    getReport: async (reportId) => {
        return await apiService.get(`/v1/reports/${reportId}`);
    },
    getMyReports: async (searchQuery, page, limit) => {
        let queryParams = [];

        if (searchQuery !== undefined && searchQuery !== null) {
            queryParams.push(`searchQuery=${encodeURIComponent(searchQuery)}`);
        }
        if (page !== undefined && page !== null) {
            queryParams.push(`page=${encodeURIComponent(page)}`);
        }
        if (limit !== undefined && limit !== null) {
            queryParams.push(`limit=${encodeURIComponent(limit)}`);
        }

        const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
        const url = `/v1/reports/mine${queryString}`;

        return await apiService.get(url);
    },
    createReport: async (requestBody) => {
        return await apiService.post(`/v1/reports`, requestBody);
    },
    deleteReport: async (reportId) => {
        return await apiService.delete(`/v1/reports/${reportId}`)
    }
};

export default reportService;

import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {useTranslation} from "react-i18next";
import '../../../utils/Loki18n';
import {t} from "i18next";

const FooterWrapper = styled.div`
    margin: 2rem 9rem;

    @media screen and (max-width: 1000px) {
        margin: 1rem;
    }
`

const VerticalCenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
`

// const AppStoreImage = styled.img`
//     cursor: pointer;
//`

const LanguageSelector = styled.select`
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0.7rem 4rem;
    text-align: left;
    border-radius: 4px;
    background: ${colors.searchBackground};
`

const CopyRightText = styled.div`
    font-size: 0.875rem;
    color: #718096;
    margin-right: 16px;
`;

const Footer = () => {
    const {i18n} = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value).then(() => window.location.reload());
        localStorage.setItem('preferredLanguage', event.target.value);
    };

    return (
        <FooterWrapper>
            {/*/!*TODO: Implement when mobile app is released*!/*/}
            {/*<Grid container spacing={2}>*/}
            {/*    <Grid item xs={12} sm={6} sx={{marginTop:"2rem"}}>*/}
            {/*        <VerticalCenterWrapper>*/}
            {/*            <p style={{textAlign: "center"}}>{t('download_mobile_app_header')}</p>*/}
            {/*            <AppStoreImage src={appStoreImage} style={{height: 48, width: "auto"}}/>*/}
            {/*            <AppStoreImage src={googlePlayImage} style={{marginTop: 10, height: 48, width: "auto"}}/>*/}
            {/*        </VerticalCenterWrapper>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sm={6} sx={{marginTop:"2rem"}}>*/}
            {/*        <VerticalCenterWrapper>*/}
            {/*            <p style={{textAlign: "center"}}>{t('select_language_header')}</p>*/}
            {/*            <LanguageSelector onChange={changeLanguage} defaultValue={i18n.language}>*/}
            {/*                <option value="lt">Lietuvių</option>*/}
            {/*                <option value="en">English</option>*/}
            {/*            </LanguageSelector>*/}
            {/*        </VerticalCenterWrapper>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} sx={{marginTop:"2rem"}}>*/}
            {/*        <p style={{textAlign: "center"}}>© 2024 Rudas portfelis, VšĮ</p>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid container spacing={2}>
                <Grid item xs={12} sx={{marginTop:"2rem"}}>
                    <VerticalCenterWrapper>
                        <p style={{textAlign: "center"}}>{t('select_language_header')}</p>
                        <LanguageSelector onChange={changeLanguage} defaultValue={i18n.language}>
                            <option value="lt">Lietuvių</option>
                            <option value="en">English</option>
                        </LanguageSelector>
                    </VerticalCenterWrapper>
                </Grid>
                <Grid item xs={12} sx={{marginTop:"2rem"}}>
                    <CopyRightText style={{textAlign: "center"}}>&copy;{t('all_rights_reserved')}</CopyRightText>
                </Grid>
            </Grid>
        </FooterWrapper>
    );
}

export default Footer
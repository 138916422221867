import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";

const Avatar = styled.img`
    border-radius: 12px;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;
    margin: 0;
`

const DetailsWrapper = styled.section`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
`

const DisplayName = styled.p`
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0px;
    margin: 0 0 0px;
    padding-left: 1px;
    width: 100%;
`

const ProfilePictureWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const BioContent = styled.p`
    font-family: 'Inter', sans-serif;
    text-align: left;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    padding-left: 1px;
`

const HoverWrapper = styled.div`
    cursor: pointer;
    @media screen and (min-width: 768px) {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }
`

export default function ProfileWidget({avatar, displayName, bio, onClick}) {
    return (
        <HoverWrapper>
            <Grid container spacing={1} sx={{cursor: 'pointer'}} onClick={onClick}>
                <Grid item xl={12}>
                    <ProfilePictureWrapper>
                        <Avatar src={avatar} alt="Avatar"/>
                    </ProfilePictureWrapper>
                </Grid>
                <Grid item xl={12}>
                    <DetailsWrapper>
                        <DisplayName>{displayName}</DisplayName>
                        <BioContent>{bio}</BioContent>
                    </DetailsWrapper>
                </Grid>
            </Grid>
        </HoverWrapper>
    );
}
import styled from "styled-components";
import colors from "../../../assets/styles/colors";

const Centered = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    margin-top: 16px;
`

const Image = styled.img`
    width: 100px;
`

const Title = styled.p`
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: ${colors.textColor};
    margin-top: 16px;
    margin-bottom: 0;
    text-align: center;
`

const Description = styled.p`
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin-top: 8px;
    margin-bottom: 0;
    text-align: center;
`


const NoResults = ({title, description, image}) => {
    return (
        <Centered>
            <Image src={image}/>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Centered>
    );
}

export default NoResults;
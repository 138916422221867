import styled from "styled-components";
import AutoBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs";
import * as React from "react";
import {useState} from "react";
import {t} from "i18next";
import colors from "../../assets/styles/colors";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LokiPrimaryButton from "../../components/common/Buttons/LokiPrimary";
import supportTicketService from "../../services/firebase/report/supportTicketService";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import {toast} from "react-toastify";
import {getCurrentUserFormatted} from "../../services/firebase/firebaseService";
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import NavBar from "../../components/common/NavigationBar/NavBar";


const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: ${colors.textColor};
`

export default function SubmitIdeaPage() {
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        idea: '',
    });

    const [errors, setErrors] = useState({
        idea: '',
    });

    const validate = () => {
        let tempErrors = {...errors};
        tempErrors.idea = values.idea.trim() ? '' : t('error_field_mandatory');
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const styleFullWidth = {
        bgcolor: 'background.paper',
        width: "100%",
        boxShadow: 0,
        borderRadius: '4px',
        p: 2,
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return
        }

        setLoading(true)

        try {
            let body = {
                idea: values.idea,
                status: 'SUBMITTED',
                requester: getCurrentUserFormatted(),
                flavor: 'Default'
            }

            await supportTicketService.submitIdea(body)
            toast.success(t('idea_submitted'))
            navigate("/help")
        } catch (e) {
            toast.error(t(e.code || 'general_error'))
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    };

    if (loading) {
        return <LokiLoader/>
    }
    return (
        <>
            <NavBar/>
            <br/>
            <Container maxWidth="md">
                <AutoBreadcrumbs/>
                <br/>
                <br/>
            <Box sx={styleFullWidth}>
                <SectionTitle>{t('idea_title')}</SectionTitle>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    sx={{background: `${colors.backgroundColor}`, borderRadius: 1, mb: 6}}
                    id="idea"
                    label={t('idea_hint')}
                    placeholder={t('idea_placeholder')}
                    multiline
                    rows={6}
                    inputProps={{
                        maxLength: 1000,
                    }}
                    value={values.idea}
                    onChange={handleChange}
                    error={!!errors.idea}
                    helperText={errors.idea}
                    name="idea"
                />
                <LokiPrimaryButton onClick={handleSubmit}>{t('submit_idea')}</LokiPrimaryButton>
            </Box>
        </Container>
            </>
    )
}
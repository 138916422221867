import {getCollection} from "../../firebase/firebaseService";

const legalService = {
    getLegalAttachments: async () => {
        const legalResponse = await getCollection("legal")
        return legalResponse
    },
};

export default legalService;

import React from 'react';
import {FaBars} from 'react-icons/fa';
import styled from 'styled-components';
import colors from "../../../assets/styles/colors";
import Sidebar from "./Sidebar";
import logo from '../../../assets/images/logo.png';

const Nav = styled.nav`
    background: ${colors.backgroundColor};
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    z-index: 9900;

    @media screen and (min-width: 769px) {
        display: none;
    }
`;

const Bars = styled(FaBars)`
    color: ${colors.textColor};
    font-size: 1.5rem;
    margin: 16px;
`;

const StyledImage = styled.img`
    border-radius: 16px;
    width: 48px;
    aspect-ratio: 1;
`;

const MobileNavbar = ({isOpen, toggle, isLoading}) => (
    <Nav>
        <StyledImage src={logo} alt='logo'/>
        <Bars onClick={toggle}/>
        <Sidebar isOpen={isOpen} toggle={toggle} isLoading={isLoading}/>
    </Nav>
);

export default MobileNavbar;

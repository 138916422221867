import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from '../assets/locales/en.json';
import lt from '../assets/locales/lt.json';


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            lt: { translation: lt }
        },
        lng: "lt",
        fallbackLng: "lt",
        interpolation: { escapeValue: false }
    });

export default i18n;

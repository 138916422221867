import React from 'react';
import {Navigate} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LokiLoader from "../components/common/Loader/LokiLoader";


/**
 * A higher-order component that manages redirection for authenticated users.
 *
 * When the authentication status is loading (as determined by `isLoading()` from `useAuth`),
 * a loader is displayed. If the user is authenticated, they are redirected to a specified route.
 * If the user is not authenticated, the child components are rendered, allowing access to the
 * current route.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered if the user is not authenticated.
 * @param {string} props.redirectTo - The path to redirect to if the user is authenticated.
 * @returns {React.ReactNode} - A React Node (component) based on the user's authentication status.
 */
const AuthenticatedRedirect = ({children, redirectTo}) => {

    const {isAuthenticated, isLoading, isVerifiedWithBackend} = useAuth();

    if (isLoading()) {
        return <LokiLoader/>;
    }

    if (isAuthenticated() && isVerifiedWithBackend()) {
        return <Navigate to={redirectTo} replace/>;
    }

    return children;
};

export default AuthenticatedRedirect
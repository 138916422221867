import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import SearchResultsGroup from "../../../components/features/Search/SearchResultsGroup";
import searchService from "../../../services/api/search/searchService";
import NoResults from "../../../components/features/Search/ListItems/NoResults";
import {Fade, Pagination} from "@mui/material";
import Filter from "../../../components/features/Search/Filter";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import miscService from "../../../services/api/misc/miscService";
import SearchResultsSkeleton from "../../../components/features/Search/Skeleton/SearchResultsSkeleton";
import FilterSkeleton from "../../../components/features/Search/Skeleton/FilterSkeleton";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import {toast} from "react-toastify";
import {t} from "i18next";

const RootWrapper = styled.div`
    padding-right: 30px;
    padding-left: 30px;
    @media screen and (max-width: 900px) {
        padding-right: 16px;
        padding-left: 16px;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    padding-right: 16px;
    margin-top: 16px;
    width: 100%;

    @media screen and (max-width: 900px) {
        padding-right: 0px;
    }
`

const MarginWrapper = styled.div`
    margin-top: 16px;
    width: 100%;
`

const SearchPage = ({initialSearchQuery}) => {
    const [searchResults, setSearchResults] = useState(null);
    const {
        isLoading: isResultsLoading,
        showLoadingUI: showResultsLoadingUI,
        setIsLoading: setIsResultsLoading
    } = useDebouncedLoading(true);

    const {
        isLoading: isFilterLoading,
        showLoadingUI: showFilterLoadingUI,
        setIsLoading: setIsFilterLoading
    } = useDebouncedLoading(true);

    const [filterRanges, setFilterRanges] = useState({
        species: [],
        breeds: [],
        sizes: []
    });
    const [lastSearchFilter, setLastSearchFilter] = useState(null); // New state to track last search filters
    const [filter, setFilter] = useState({
        searchQuery: initialSearchQuery || '',
        breed: -1,
        species: -1,
        size: -1,
        ageTo: -1,
        gender: -1,
        sort: '',
        page: 1
    });

    const areFiltersDefault = () => {
        const defaultFilter = {
            searchQuery: '',
            breed: -1,
            species: -1,
            size: -1,
            ageTo: -1,
            gender: -1,
            sort: '',
            page: 1
        };

        return JSON.stringify(filter) === JSON.stringify(defaultFilter);
    };

    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        const initFetch = async () => {
            setIsFilterLoading(true);
            try {
                const filtersData = await miscService.getFilters();
                setFilterRanges(filtersData);
            } catch (err) {
                toast.error(t(err.code || 'general_error'))
            } finally {
                setIsFilterLoading(false);
            }
        };

        initFetch();
        performSearch()
    }, []);

    const filtersAreEqual = (filter1, filter2) => {
        return JSON.stringify(filter1) === JSON.stringify(filter2);
    };

    const performSearch = async (newFilter) => {
        const searchFilter = newFilter || filter;
        if (filtersAreEqual(searchFilter, lastSearchFilter)) {
            return;
        }

        setIsResultsLoading(true);
        try {
            const data = await searchService.search(searchFilter);
            setSearchResults(data);
            setLastSearchFilter(searchFilter)
        } catch (err) {
            toast.error(t(err.code || 'general_error'))
        } finally {
            setIsResultsLoading(false);
        }
    };

    const search = async () => {
        await performSearch()
    }

    const clearFilters = async () => {
        const newFilter = {
            searchQuery: '',
            breed: -1,
            species: -1,
            size: -1,
            ageTo: -1,
            gender: -1,
            sort: '',
            page: 1
        };

        setFilter(newFilter)
        await performSearch(newFilter)
    }

    const handlePageChange = async (event, value) => {
        const newFilter = {...filter, page: value}
        setFilter(newFilter);
        await performSearch(newFilter)
    };

    return (
        <div>
            <NavBar/>
            <RootWrapper>
                <Grid container spacing={2} sx={{paddingBottom: "3rem"}}>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        {showFilterLoadingUI ?
                            <FilterSkeleton filterCount={4}/>
                            :
                            isFilterLoading ? <div/> : <Filter filterRanges={filterRanges}
                                                               filters={filter}
                                                               setFilters={setFilter}
                                                               onSearchClick={search}
                                                               applyFilter={search}/>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={10}>
                        {showResultsLoadingUI ?
                            <Fade in={true} timeout={300}>
                                <ContentWrapper>
                                    <MarginWrapper>
                                        <SearchResultsSkeleton/>
                                        <br/>
                                        <SearchResultsSkeleton/>
                                    </MarginWrapper>
                                </ContentWrapper>
                            </Fade>
                            :
                            <ContentWrapper>
                                {!searchResults || searchResults.content?.length === 0 ? (
                                    isResultsLoading ? <div/> :
                                        <NoResults onResetClick={clearFilters} hasFilters={!areFiltersDefault()}/>
                                ) : (
                                    <div style={{width: '100%'}}>
                                        <Fade in={true} timeout={300}>
                                            <div>
                                                {searchResults.content && searchResults.content.length > 0 && (
                                                    <MarginWrapper>
                                                        <SearchResultsGroup
                                                            key="listings"
                                                            listings={searchResults.content}/>
                                                    </MarginWrapper>
                                                )}
                                            </div>
                                        </Fade>

                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '2rem'
                                        }}>
                                            <Pagination
                                                count={searchResults.totalPages || 0}
                                                page={filter.page}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                shape="rounded"/>
                                        </div>
                                    </div>
                                )}
                            </ContentWrapper>
                        }
                    </Grid>
                </Grid>
            </RootWrapper>
        </div>
    );
}

export default SearchPage;
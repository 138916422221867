import Grid from "@mui/material/Grid";
import React from "react";
import colors from "../../../assets/styles/colors";
import styled from "styled-components";
import {Phone, Place} from "@mui/icons-material";
import {Divider} from "@mui/material";
import {parsePhoneNumber} from "libphonenumber-js";

const Avatar = styled.img`
    border-radius: 8px;
    width: 80%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;
`

const DetailsWrapper = styled.section`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
    gap: 1px;
`

const DisplayName = styled.h4`
    color: ${colors.semiPrimaryTextColor};
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 0;
`

const ProfilePictureWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const BioContent = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0 0 6px;
`

const ContactContent = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
    gap: 6px;
`

export default function SmallProfileWidget({profile, onClick}) {

    const getFormattedPhoneNumber = () => {
        const phoneNumber = parsePhoneNumber(profile.location.contactNumber)
        return phoneNumber.formatInternational()
    }
    return (
        <Grid container spacing={2} sx={{cursor: 'pointer'}} onClick={onClick}>
            <Grid item xs={4} md={2}>
                <ProfilePictureWrapper>
                    <Avatar src={profile.avatarUrl} alt="Avatar"/>
                </ProfilePictureWrapper>
            </Grid>
            <Grid item xs={8} md={10}>
                <DetailsWrapper>
                    <DisplayName>{profile.displayName}</DisplayName>
                    <BioContent>{profile.bio}</BioContent>
                    <Divider sx={{width: '100%'}}/>
                    <Item>
                        <Place color="primary"/>
                        <ContactContent>{`${profile.location.street}, ${profile.location.city}, ${profile.location.country}`}</ContactContent>
                    </Item>
                    <Item>
                        <Phone color="primary"/>
                        <ContactContent>{getFormattedPhoneNumber()}</ContactContent>
                    </Item>

                </DetailsWrapper>
            </Grid>
        </Grid>
    );
}
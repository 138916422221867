import React from "react";
import Grid from '@mui/material/Grid';
import FeaturedItem from "./FeaturedItem";
import {Fade} from "@mui/material";
import Container from "@mui/material/Container";

const MAX_FEATURED_ITEMS = 5

const FeaturedSection = React.memo(({feedItem}) => {
    const {featured} = feedItem || {};

    if (!featured) {
        return;
    }

    return (
        <Fade in={true} timeout={300}>
            <Container maxWidth="xl">
                <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                    {featured.slice(0, MAX_FEATURED_ITEMS).map((item) => (
                        <Grid key={featured.id} item xs={12} sm={4} md={3} lg={2.4}>
                            <FeaturedItem item={item}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Fade>
    );
});

export default FeaturedSection

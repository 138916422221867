import React from "react";
import styled from "styled-components";
import {t} from "i18next";
import {useNavigate} from 'react-router-dom';


const ProfileDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

const ProfileTitle = styled.p`
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 0.95rem;
    font-weight: 600;
    padding-top: 6px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 1px;
`

const ProfileSubtitle = styled.p`
    max-height: 2em;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0px;
    padding-left: 1px;
`

const ProfileThumbnail = styled.img`
    aspect-ratio: 1/1;
    border-radius: 8px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
`

const HoverWrapper = styled.div`
    cursor: pointer;
    @media screen and (min-width: 768px) {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }
`

const ProfileResultItem = ({shelterSearchResult}) => {
    let navigate = useNavigate();
    const { profileId, thumbnailUrl, title, localizedDescription } = shelterSearchResult || {};

    function handleClick() {
        navigate(`/profile/${profileId}`);
    }

    return (
        <HoverWrapper onClick={handleClick}>
            {thumbnailUrl && <ProfileThumbnail src={thumbnailUrl} />}
            <ProfileDataWrapper>
                <ProfileTitle>{title}</ProfileTitle>
                <ProfileSubtitle>{t(localizedDescription)}</ProfileSubtitle>
            </ProfileDataWrapper>
        </HoverWrapper>
    )
}

export default ProfileResultItem
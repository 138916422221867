import React from "react";
import {Grid, Skeleton} from "@mui/material";
import styled from "styled-components";

const ROWS = 5;

const SectionWrapper = styled.div`
    margin-left: 3rem;
    margin-right: 3rem;

    @media screen and (max-width: 1000px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 2rem;
    }
`;

const AspectRatioWrapper = styled.div`
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
`;

const ContentInside = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    overflow: hidden;
`;


const SearchResultsSkeleton = () => {
    return (
        <SectionWrapper>
            <Grid container spacing={2}>
                {Array.from(new Array(ROWS)).map((_, index) => (
                    <Grid item key={index} xs={12} sm={4} md={3} lg={2.4}>
                        <AspectRatioWrapper>
                            <ContentInside>
                                <Skeleton variant="rectangular" width="100%" height="100%"
                                          sx={{borderRadius: '8px'}}/>
                            </ContentInside>
                        </AspectRatioWrapper>
                        <Skeleton variant="text"/>
                        <Skeleton variant="text" width="60%"/>
                    </Grid>
                ))}

            </Grid>
        </SectionWrapper>
    );
};

export default SearchResultsSkeleton;
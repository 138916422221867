import apiService from '../baseApiService';

const miscService = {
    getFilters: async () => {
        return await apiService.get(`/v1/misc/filters`);
    },
    searchBreeds: async (searchQuery) => {
        return await apiService.get(`/v1/misc/breeds?searchQuery=${searchQuery}`)
    },
    getMisc: async () => {
        return await apiService.get(`/v1/misc`);
    },
    getCities: async () => {
        return await apiService.get(`/v1/misc/cities`);
    }
};

export default miscService;

import imageCompression from 'browser-image-compression';
import {uploadImageToFirebase} from "../../firebase/firebaseService";


const mediaService = {
    uploadFile: async (file, folder = "unknown", compress = false) => {
        const maxFileSize = 5 * 1024 * 1024;
        if (file.size > maxFileSize) {
            const error = new Error("File size exceeds the 5MB limit.");
            error.code = "file_size_limit_exceeded";
            throw error;
        }

        const url = await uploadImageToFirebase(file, folder)

        if (compress) {
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            const compressedUrl = await uploadImageToFirebase(compressedFile, folder)
            return {url: url, compressedUrl: compressedUrl}
        }
        return {url: url}
    }
};

export default mediaService;

import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Report from "./Report";
import {Fade} from "@mui/material";
import {t} from "i18next";

const SectionWrapper = styled.div`
    margin-left: 8rem;
    margin-right: 8rem;
    margin-top: 2rem;

    @media screen and (max-width: 1000px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 2rem;
    }
`

const SectionHeader = styled.h3`
    font-weight: 600;
    font-size: 1.2rem;
`

const ReportSection = ({title, reports}) => {
    return (
        <Fade in={true} timeout={300}>
            <SectionWrapper>
                <SectionHeader>{t(title)}</SectionHeader>
                <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                    {reports.map((report) => (
                        <Grid key={report.id} item xs={12} sm={4} md={3} lg={2.4}>
                            <Report thumbnailUrl={report.thumbnails[0]} reportId={report.id} key={report.id}/>
                        </Grid>
                    ))}
                </Grid>
            </SectionWrapper>
        </Fade>
    );
}

export default ReportSection;

import React from "react";
import styled from "styled-components";
import {t} from "i18next";
import {useNavigate} from 'react-router-dom';


export const ListingDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

export const ListingTitle = styled.p`
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding-top: 6px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-left: 1px;

`

export const ListingSubtitle = styled.p`
    max-height: 2em;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0px;
    padding-left: 1px;
`

export const ListingThumbnail = styled.img`
    aspect-ratio: 1/1;
    border-radius: 12px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
`

const HoverWrapper = styled.div`
    cursor: pointer;
    @media screen and (min-width: 768px) {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;

        &:hover {
            opacity: 0.5;
        }
    }
`

const Listing = ({listing}) => {
    let navigate = useNavigate();
    const { title, pet } = listing || {};
    const thumbnail = pet?.thumbnails?.[0];

    const heading = `${pet.name} • ${t(pet.breed.localizationKey)}, ${t(pet.breed.species.localizationKey)}`

    function handleClick() {
        navigate(`/listing/${listing.id}`);
    }

    return (
        <HoverWrapper onClick={handleClick}>
            {thumbnail && <ListingThumbnail src={thumbnail} />}
            <ListingDataWrapper>
                <ListingTitle>{heading}</ListingTitle>
                <ListingSubtitle>{title}</ListingSubtitle>
            </ListingDataWrapper>
        </HoverWrapper>
    )
}

export default Listing
import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Listing from "./Listing";
import {t} from "i18next";
import {Fade} from "@mui/material";
import Container from "@mui/material/Container";

const MAX_CATEGORY_ITEMS = 5;

const SectionHeader = styled.h4`
    font-weight: 600;
    //TODO: Implement redirect to search with filters
    //cursor: pointer;
    margin-bottom: 1rem;
`;

const ListingSection = React.memo(({feedItem}) => {
    const {localizationKey, listings} = feedItem || {};

    if (!listings || !localizationKey) {
        return
    }

    return (
        <Fade in={true} timeout={300}>
            <Container maxWidth="xl">
                <br/>
                <SectionHeader>{t(localizationKey)}</SectionHeader>
                <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                    {listings.slice(0, MAX_CATEGORY_ITEMS).map((listing) => (
                        <Grid key={listing.id} item xs={12} sm={4} md={3} lg={2.4}>
                            <Listing listing={listing}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Fade>
    );
});

export default ListingSection
import React, {useEffect, useState} from "react";
import {Backdrop, IconButton} from "@mui/material";

import CloseIcon from "../../../assets/images/icons/CloseIcon";
import styled from "styled-components";

const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const Image = styled.div`
    position: relative;
`

const GalleryImage = styled.img`
    height: 35rem;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 992px) {
        height: 350px;
    }
`

const BackdropGallery = ({ open, handleClose, image }) => {
    const [backdropImage, setBackdropImage] = useState(image);

    useEffect(() => {
        setBackdropImage(image);
    }, [image]);

    return (
        <Backdrop
            onClick={handleClose}
            sx={{
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <Content>
                <IconButton
                    onClick={handleClose}
                    sx={{ color: "#fff", bgcolor: "transparent", alignSelf: "end" }}
                >
                    <CloseIcon fillColor={"#fff"} />
                </IconButton>
                <Image>
                    <GalleryImage
                        src={backdropImage}
                        style={{ cursor: "auto" }}
                    />
                </Image>
            </Content>
        </Backdrop>
    );
};

export default BackdropGallery;
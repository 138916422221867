import styled from "styled-components";
import {t} from "i18next";
import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import {HashLoader} from "react-spinners";
import colors from "../../../assets/styles/colors";
import {sendVerificationEmail} from "../../../services/firebase/firebaseService";

const Header = styled.p`
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 1.5rem;
`

const Message = styled.p`
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Button = styled.p`
    font-family: 'Inter', sans-serif;
    color: ${colors.secondaryColor};
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    text-align: center;
    cursor: pointer;
    
    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            opacity: 0.5;
        }
    }
`
const VerifyEmailAlert = ({onResult, email}) => {
    const [loading, setLoading] = useState(false);
    const sendEmail = async () => {
        setLoading(true)
        let result = await sendVerificationEmail()
        onResult(result)
        setLoading(false)
    }

    return (
        <Paper sx={{mb: 6, backgroundColor:`${colors.emailAlertBackground}`, borderRadius:'8px', border:`1px solid ${colors.emailAlertBorder}`}} elevation={0}>
            <Content>
                <Header>{t('verify_email_header')}</Header>
                <Message>{email}</Message>
                {loading ?
                    (
                        <div style={{margin: '1.5rem'}}>
                            <HashLoader color={colors.secondaryColor} size={20}/>
                        </div>
                    ) : (
                        <Button onClick={sendEmail} style={{
                            margin: '1.5rem',
                            width: '80%'
                        }}>{t('resend_email_verification')}</Button>
                    )
                }
            </Content>
        </Paper>
    )
}

export default VerifyEmailAlert;
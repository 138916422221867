import styled from "styled-components";
import * as React from "react";
import {t} from "i18next";
import colors from "../../assets/styles/colors";
import {useNavigate} from "react-router-dom";
import Navbar from "../../components/common/NavigationBar/NavBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

const SectionTitle = styled.h3`
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
    color: ${colors.textColor};
`
const SectionDescription = styled.p`
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
    color: ${colors.textColor};
`

const Title = styled.h3`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-align: center;
`

export default function HelpPage() {
    let navigate = useNavigate()

    const styleFullWidth = {
        width: "100%",
        borderRadius: '6px',
        cursor: 'pointer',
        p: 2,
        '&:hover': {
            transition: 'all 0.2s ease-in-out',
            opacity: 0.5
        },
    };

    return (
        <>
            <Navbar/>
            <Container maxWidth="md">
                <br/>
                <Title>{t('help_title')}</Title>
                <br/>
                <br/>
                <Paper sx={styleFullWidth} elevation="3" onClick={() => navigate("new-ticket")}>
                    <SectionTitle>{t('help_submit_ticket_title')}</SectionTitle>
                    <SectionDescription>{t('help_submit_ticket_description')}</SectionDescription>
                </Paper>
                <br/>

                <Paper sx={styleFullWidth} elevation="3" onClick={() => navigate("report-bug")}>
                    <SectionTitle>{t('help_report_bug_title')}</SectionTitle>
                    <SectionDescription>{t('help_report_bug_description')}</SectionDescription>
                </Paper>
                <br/>

                <Paper sx={styleFullWidth} elevation="3" onClick={() => navigate("idea")}>
                    <SectionTitle>{t('help_suggest_functionality_title')}</SectionTitle>
                    <SectionDescription>{t('help_suggest_functionality_description')}</SectionDescription>
                </Paper>
            </Container>
        </>
    )
}
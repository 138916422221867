import NavBar from "../../../components/common/NavigationBar/NavBar";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";

const Header = styled.h1`
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    margin-top: 32px;
    color: ${colors.textColorDark};
    font-weight: 800;
`
const AboutUsPage = () => {
    return (
        <>
            <NavBar/>
            <Header>About us</Header>
        </>
    );
}

export default AboutUsPage
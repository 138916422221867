import * as firebaseService from "./firebaseService";
import mixpanel from "mixpanel-browser";

export const logListingPreview = (listingId) => {
    const uid = firebaseService.getUid()
    mixpanel.track('listing_preview', {
        'listing_id': listingId,
        'firebase_uid': uid
    })
}

export const logShelterPreview = (shelterId) => {
    const uid = firebaseService.getUid()
    mixpanel.track('shelter_preview', {
        'shelter_id': shelterId,
        'firebase_uid': uid
    })
}

export const logMissingReport = (reportId) => {
    const uid = firebaseService.getUid()
    mixpanel.track('report_preview', {
        'report_id': reportId,
        'firebase_uid': uid
    })
}
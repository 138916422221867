// timeUtils.js

import {t} from "i18next";

function timeAgo(timestamp) {
    const adjustedTimestamp = timestamp.toString().length > 10 ? Math.floor(timestamp / 1000) : timestamp;
    const now = Math.floor(new Date().getTime() / 1000); // Current time in seconds
    const secondsPast = now - adjustedTimestamp;

    // Just now
    if (secondsPast < 60) {
        return t('justNow');
    }

    // Minutes
    if (secondsPast < 3600) {
        const minutes = Math.round(secondsPast / 60);
        return t(minutes === 1 ? 'minute' : 'minutes', {count: minutes});
    }

    // Hours
    if (secondsPast < 86400) {
        const hours = Math.round(secondsPast / 3600);
        return t(hours === 1 ? 'hour' : 'hours', {count: hours});
    }

    // Days (not specified in your translations, but handling for completeness)
    if (secondsPast < 2592000) {
        const days = Math.round(secondsPast / 86400);
        // Assuming you would have a 'days' translation similar to 'years'
        return t(days === 1 ? 'day' : 'days', {count: days});
    }

    // Years
    const years = Math.round(secondsPast / 31536000) + 1;
    return t(years === 1 ? 'year' : 'years', {count: years});
}

export default timeAgo;

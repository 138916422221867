import React from 'react';
import {Navigate} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LokiLoader from "../components/common/Loader/LokiLoader";
import {reload} from "../services/firebase/firebaseService";

/**
 * A higher-order component that manages access to protected routes based on authentication status and phone number verification.
 *
 * It displays a loader while the authentication status is loading (`isLoading()` from `useAuth`).
 * If the user is not authenticated, it redirects to the `/partner` route.
 * If the user is authenticated but has no verified phone number, it redirects to the `/phone-confirmation` route.
 * Otherwise, it renders the child components, indicating that the user has passed the authentication and phone number checks.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered if the user is authenticated and has a verified phone number.
 * @returns {React.ReactNode} - A React Node (component) based on the authentication status and phone number verification.
 */
const ProtectedRoute = ({children}) => {
    const {isAuthenticated, isLoading, user} = useAuth();

    if (isLoading()) {
        return <LokiLoader/>;
    }

    if (!isAuthenticated()) {
        return <Navigate to="/sign-in" replace/>;
    }


    if (!user.emailVerified) {
        reload().then(() => {
            return <Navigate to="/email" replace/>;
        });
    }

    return children;
};

export default ProtectedRoute;

import React from "react";
import NotFoundImage from "../../../assets/images/not_found.svg"
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {Link} from 'react-router-dom';
import {t} from "i18next";

const VerticalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    animation: fadeIn 0.5s ease-in-out;
`

const Image = styled.img`
    width: 22rem;
    height: auto;
    margin-top: 3rem;
`

const BackButton = styled(Link)`
    margin-left: 10px;
    border-radius: 4px;
    outline: none;
    border: none;
    text-decoration: none;
    text-underline: none;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 0.9rem;
    font-weight: 500;
    padding: 16px 42px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${colors.primaryColor};
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
`

const NotFound = () => {
    return (
        <VerticalWrapper>
            <Image src={NotFoundImage}/>
            <h1 style={{textAlign:"center", paddingRight:"0.6rem", paddingLeft:"0.6rem"}}>404</h1>
            <h2 style={{textAlign:"center", paddingRight:"0.6rem", paddingLeft:"0.6rem"}}>{t('404_message')}</h2>
            <ButtonWrapper>
                <BackButton to="/">{t('404_button_back_home')}</BackButton>
            </ButtonWrapper>
        </VerticalWrapper>
    );
}

export default NotFound
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LokiLogo from "../../../assets/images/logo.svg"
import styled from "styled-components";
import {t} from "i18next";
import LokiSecondaryButton from "../../../components/common/Buttons/LokiSecondary";
import colors from "../../../assets/styles/colors";
import sessionService from "../../../services/api/session/sessionService";
import {toast} from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import {getCurrentUserIdToken, updateDisplayName, verifyClaims} from "../../../services/firebase/firebaseService";
import LokiLoader from "../../../components/common/Loader/LokiLoader";

const FullscreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const ClickableSpan = styled.span`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.primaryColor};

    &:hover {
        transition: all 0.2s ease-in-out;
        opacity: 0.5;
    }
`

const TCText = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-top: 0;
    text-align: center;
    color: ${colors.textColor};
`


const KycPage = () => {
    const {logout, setVerifiedWithBackend, user} = useAuth()

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        displayName: '',
    });

    const [formErrors, setFormErrors] = useState({
        displayName: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const validate = () => {
        let errors = {};
        errors.displayName = formValues.displayName ? '' : t('error_field_mandatory');

        setFormErrors(errors);
        return Object.values(errors).every(x => x === "");
    };

    const getSessionCreateDto = async () => {
        const firebaseToken = await getCurrentUserIdToken();

        const sessionCreateDto = {
            firebaseToken: firebaseToken
        };

        return sessionCreateDto
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validate()) {
            try {
                setIsLoading(true)
                await updateDisplayName(formValues.displayName)

                const sessionCreateDto = await getSessionCreateDto()
                await sessionService.registerSession(sessionCreateDto)

                await verifyClaims()
                await setVerifiedWithBackend(true)

                if (user?.emailVerified) {
                    navigate("/")
                } else {
                    navigate("/email")
                }
                toast.success(t('sign_up_success_message'));
            } catch (error) {
                toast.error(t(error.code || 'general_error'))
                await logout()
            } finally {
                setIsLoading(false)
            }
        }
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues({...formValues, [name]: value});
    };

    if (isLoading) {
        return (
            <FullscreenContainer>
                <Container component="main" maxWidth="xs" sx={{
                    zIndex: 999, marginRight: '16px',
                    marginLeft: '16px'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={LokiLogo} style={{
                            borderRadius: 22,
                            marginBottom: "1rem",
                            marginTop: "1rem",
                            cursor: "pointer",
                            width: '5rem'
                        }} alt="Lookì logo"/>

                        <LokiLoader/>
                    </Box>
                </Container>
            </FullscreenContainer>
        )
    }

    return (
        <FullscreenContainer>
            <Container component="main" maxWidth="xs"
                       style={{
                           boxShadow: '0px 0px 12px rgba(0,0,0,0.2)',
                       }}
                       sx={{
                           zIndex: 999,
                           borderRadius: '12px',
                           marginTop: "5rem",
                           marginRight: '16px',
                           marginLeft: '16px',
                           marginBottom: '2rem'
                       }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={LokiLogo} style={{
                        borderRadius: 22,
                        marginBottom: "1rem",
                        marginTop: "1rem",
                        cursor: "pointer",
                        width: '5rem'
                    }} alt="Lookì logo"/>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="displayName"
                            label={t('display_name')}
                            name="displayName"
                            autoComplete="displayName"
                            inputProps={{
                                maxLength: 100,
                            }}
                            sx={{
                                background: `${colors.backgroundColor}`,
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            autoFocus
                            value={formValues.displayName}
                            onChange={handleChange}
                            error={!!formErrors.displayName}
                            helperText={formErrors.displayName}
                        />

                        <LokiSecondaryButton
                            onClick={handleSubmit}
                            style={{marginTop: "2rem", marginBottom: "2rem"}}>{t('submit')}</LokiSecondaryButton>

                        <TCText>{t('terms_and_conditions_accept_text').replace('{buttonText}', t('submit'))}
                            <ClickableSpan
                                onClick={() => window.open('/terms-conditions', '_blank')}>{t('terms_and_conditions')}</ClickableSpan> {t('and')}
                            <ClickableSpan
                                onClick={() => window.open('/privacy-policy', '_blank')}>{t('privacy_policy')}</ClickableSpan>.
                        </TCText>
                    </Box>
                </Box>
            </Container>
        </FullscreenContainer>
    );
};

export default KycPage;

import React, {useEffect, useState} from 'react';
import NavBar from "../../../components/common/NavigationBar/NavBar";
import reportService from "../../../services/api/report/reportService";
import ReportSection from "../../../components/features/Report/ReportSection";
import styled from "styled-components";
import {t} from "i18next";
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Grid from "@mui/material/Grid";
import {Divider, Fade, Pagination} from "@mui/material";
import ReportSkeleton from "../../../components/features/Report/Skeleton/ReportSkeleton";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import {toast} from "react-toastify";
import colors from "../../../assets/styles/colors";
import Container from "@mui/material/Container";

const AnimatedWrapper = styled.div`
    padding-bottom: 2rem;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const FilterTitle = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
    padding-bottom: 1rem;
`

const SectionWrapper = styled.div`
    margin-left: 8rem;
    margin-right: 8rem;
    margin-top: 2rem;

    @media screen and (max-width: 1000px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 2rem;
    }
`

const Selector = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 1rem;
    width: 100%;
    text-align: left;
    border-radius: 4px;
    margin-bottom: 6px;
    background: ${colors.searchBackground};
`

const MissingPage = () => {
    const [reports, setReports] = useState(null);
    const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [type, setType] = useState("Lost");

    const fetchReports = async (dateFrom, page = 1, type = "Lost") => {
        setIsLoading(true);

        try {
            const limit = 10;
            const response = await reportService.getReports(dateFrom, undefined, type, page, limit);
            setReports(response.content);
            setTotalPages(response.totalPages);
        } catch (err) {
            toast.error(t(err.code || 'general_error'));
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchReports(selectedDate, currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTypeSelect = async (event) => {
        const selection = event.target.value
        setType(selection)

        let from = undefined
        if (selectedDate) {
            from = dayjs(selectedDate).unix()
        }
        await fetchReports(from, 1, selection)
    }

    const handleDateChange = async (date) => {
        setSelectedDate(date);
        await fetchReports(dayjs(date).unix(), 1, type)
    };

    if (showLoadingUI) {
        return (
            <>
                <AnimatedWrapper>
                    <NavBar/>
                    <Fade in={true} timeout={300}>
                        <div>
                            <SectionWrapper>
                                <Container maxWidth="sm">
                                    <h1 style={{textAlign: 'center', fontWeight: '600'}}>{t('missing_title')}</h1>
                                    <p style={{textAlign: 'center'}}>{t('missing_subtitle')}</p>
                                    <Grid container spacing={4}
                                          style={{paddingLeft: 16, paddingRight: 16, marginTop: "2rem"}}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <FilterTitle>{t('missing_type')}</FilterTitle>
                                            <Fade in={true} timeout={300}>
                                                <Selector value={type} onChange={handleTypeSelect}>
                                                    <option value="Lost">{t('missing_type_lost')}</option>
                                                    <option value="Found">{t('missing_type_found')}</option>
                                                </Selector>
                                            </Fade>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <FilterTitle>{t('missing_when_lost')}</FilterTitle>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Fade in={true} timeout={300}>
                                                    <DatePicker
                                                        label={t('pick_date')}
                                                        value={selectedDate}
                                                        slotProps={{textField: {fullWidth: true}}}
                                                        okText={t('ok')}
                                                        cancelText={t("close")}
                                                        maxDate={dayjs()}
                                                        onChange={handleDateChange}
                                                    />
                                                </Fade>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </SectionWrapper>
                            <br/>
                            <br/>
                            <ReportSkeleton/>
                            <ReportSkeleton/>
                        </div>
                    </Fade>
                </AnimatedWrapper>
            </>
        )
    }

    const handlePageChange = async (event, value) => {
        setCurrentPage(value);

        let from = undefined
        if (selectedDate) {
            from = dayjs(selectedDate).unix()
        }

        await fetchReports(from, value, type);
    };

    return (
        <>
            <AnimatedWrapper>
                <NavBar/>
                {isLoading ? <div/> :
                    <div>
                        <SectionWrapper>
                            <Container maxWidth="sm">
                                <h1 style={{textAlign: 'center', fontWeight: '600'}}>{t('missing_title')}</h1>
                                <p style={{textAlign: 'center'}}>{t('missing_subtitle')}</p>
                                <Grid container spacing={4}
                                      style={{paddingLeft: 16, paddingRight: 16, marginTop: "2rem"}}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FilterTitle>{t('missing_type')}</FilterTitle>
                                        <Fade in={true} timeout={300}>
                                            <Selector value={type} onChange={handleTypeSelect}>
                                                <option value="Lost">{t('missing_type_lost')}</option>
                                                <option value="Found">{t('missing_type_found')}</option>
                                            </Selector>
                                        </Fade>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FilterTitle>{t('missing_when_lost')}</FilterTitle>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Fade in={true} timeout={300}>
                                                <DatePicker
                                                    label={t('pick_date')}
                                                    value={selectedDate}
                                                    slotProps={{textField: {fullWidth: true}}}
                                                    okText={t('ok')}
                                                    cancelText={t("close")}
                                                    maxDate={dayjs()}
                                                    onChange={handleDateChange}
                                                />
                                            </Fade>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Container>

                            <Divider sx={{mt: 4}}/>
                        </SectionWrapper>

                        <ReportSection reports={reports}/>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            sx={{mt: 6, mb: 6, display: 'flex', justifyContent: 'center'}}
                            showLastButton
                            showFirstButton
                            variant="outlined"
                            shape="rounded"/>
                    </div>
                }
            </AnimatedWrapper>
        </>
    )
        ;
};

export default MissingPage;
import React from "react";
import Container from "@mui/material/Container";

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="lg">
            <div style={{fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif", padding: "1em"}}>
                <strong>Privacy Policy</strong>
                <p>
                    This privacy policy applies to the Looki Prielgaudai app (hereby referred to as "Application") for
                    mobile
                    devices that was created by Rudas portfelis, VšĮ (hereby referred to as "Service Provider") as a
                    Free service.
                    This service is intended for use "AS IS".
                </p>
                <br/>
                <strong>Information Collection and Use</strong>
                <p>
                    The Application collects information when you download and use it. This information may include
                    information
                    such as
                </p>
                <ul>
                    <li>Your device's Internet Protocol address (e.g. IP address)</li>
                    <li>The pages of the Application that you visit, the time and date of your visit, the time spent on
                        those pages
                    </li>
                    <li>The time spent on the Application</li>
                    <li>The operating system you use on your mobile device</li>
                </ul>
                <p>The Application does not gather precise information about the location of your mobile device.</p>
                <br/>
                <p>
                    The Application collects your device's location, which helps the Service Provider determine your
                    approximate
                    geographical location and make use of it in the following ways:
                </p>
                <ul>
                    <li>
                        <strong>Geolocation Services:</strong> The Service Provider utilizes location data to provide
                        features such
                        as personalized content, relevant recommendations, and location-based services.
                    </li>
                    <li>
                        <strong>Analytics and Improvements:</strong> Aggregated and anonymized location data helps the
                        Service
                        Provider analyze user behavior, identify trends, and improve the overall performance and
                        functionality of the
                        Application.
                    </li>
                    <li>
                        <strong>Third-Party Services:</strong> Periodically, the Service Provider may transmit
                        anonymized location
                        data to external services. These services assist in enhancing the Application and optimizing
                        their offerings.
                    </li>
                </ul>
                <br/>
                <p>
                    The Service Provider may use the information you provide to contact you from time to time to provide
                    you with
                    important information, required notices, and marketing promotions.
                </p>
                <br/>
                <p>
                    For a better experience while using the Application, the Service Provider may require you to provide
                    certain
                    personally identifiable information. The information that the Service Provider requests will be
                    retained by
                    them and used as described in this privacy policy.
                </p>
                <br/>
                <strong>Third Party Access</strong>
                <p>
                    Only aggregated, anonymized data is periodically transmitted to external services to aid the Service
                    Provider
                    in improving the Application and their service. The Service Provider may share your information with
                    third
                    parties in the ways described in this privacy statement.
                </p>
                <p>
                    The Application utilizes third-party services that have their own Privacy Policies about handling
                    data. Below
                    are the links to the Privacy Policies of the third-party service providers used by the Application:
                </p>
                <ul>
                    <li>
                        <a
                            href="https://www.google.com/policies/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Google Play Services
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://firebase.google.com/support/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Firebase Crashlytics
                        </a>
                    </li>
                </ul>
                <br/>
                <p>
                    The Service Provider may disclose User Provided and Automatically Collected Information:
                </p>
                <ul>
                    <li>As required by law, such as to comply with a subpoena, or similar legal process</li>
                    <li>
                        When they believe in good faith that disclosure is necessary to protect their rights, protect
                        your safety or
                        the safety of others, investigate fraud, or respond to a government request
                    </li>
                    <li>
                        With their trusted service providers who work on their behalf, do not have an independent use of
                        the
                        information disclosed to them, and have agreed to adhere to the rules set forth in this privacy
                        statement
                    </li>
                </ul>
                <br/>
                <strong>Opt-Out Rights</strong>
                <p>
                    You can stop all collection of information by the Application easily by uninstalling it. You may use
                    the
                    standard uninstall processes available as part of your mobile device or via the mobile application
                    marketplace
                    or network.
                </p>
                <br/>
                <strong>Data Retention Policy</strong>
                <p>
                    The Service Provider will retain User Provided data for as long as you use the Application and for a
                    reasonable
                    time thereafter. If you'd like them to delete User Provided Data that you have provided via the
                    Application,
                    please contact them at info@rudasportfelis.lt and they will respond in a reasonable time.
                </p>
                <br/>
                <strong>Children</strong>
                <p>
                    The Service Provider does not knowingly solicit data from or market to children under the age of 13.
                    If they
                    discover that a child under 13 has provided personal information, it will be immediately deleted.
                    Parents or
                    guardians can contact the Service Provider at info@rudasportfelis.lt for assistance.
                </p>
                <br/>
                <strong>Security</strong>
                <p>
                    The Service Provider is concerned about safeguarding the confidentiality of your information. They
                    provide
                    physical, electronic, and procedural safeguards to protect information they process and maintain.
                </p>
                <br/>
                <strong>Changes</strong>
                <p>
                    This Privacy Policy may be updated from time to time for any reason. The Service Provider will
                    notify you of
                    changes by updating this page with the new Privacy Policy. Continued use is deemed approval of all
                    changes.
                </p>
                <br/>
                <p>This privacy policy is effective as of 2024-11-20</p>
                <br/>
                <strong>Your Consent</strong>
                <p>
                    By using the Application, you are consenting to the processing of your information as set forth in
                    this Privacy
                    Policy now and as amended by us.
                </p>
                <br/>
                <strong>Contact Us</strong>
                <p>
                    If you have any questions regarding privacy while using the Application, or have questions about the
                    practices,
                    please contact the Service Provider via email at info@rudasportfelis.lt.
                </p>
                <hr/>
                <p>
                    This privacy policy page was generated by{" "}
                    <a
                        href="https://app-privacy-policy-generator.nisrulz.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        App Privacy Policy Generator
                    </a>
                </p>
            </div>
        </Container>
    );
};

export default PrivacyPolicy;

import {useEffect, useState} from 'react';

/**
 * Custom hook to manage debounced loading state.
 *
 * @param {boolean} isLoading The initial loading state.
 * @param {number} debounceDelay Delay in milliseconds to wait before showing the loading UI.
 * @param {number} hideDelay Delay in milliseconds to wait before hiding the loading UI after loading is complete.
 * @returns An object containing the showLoadingUI state and a setter for the isLoading state.
 */
export default function useDebouncedLoading(isLoadingInitially = true, debounceDelay = 500, hideDelay = 400) {
    const [isLoading, setIsLoading] = useState(isLoadingInitially);
    const [showLoadingUI, setShowLoadingUI] = useState(false);

    useEffect(() => {
        let loadingTimeout;
        let hideLoadingTimeout;

        if (isLoading) {
            loadingTimeout = setTimeout(() => {
                setShowLoadingUI(true);
            }, debounceDelay);
        } else {
            setShowLoadingUI(false);
            clearTimeout(loadingTimeout);
            hideLoadingTimeout = setTimeout(() => {
                setShowLoadingUI(false);
            }, hideDelay);
        }

        return () => {
            if (loadingTimeout) clearTimeout(loadingTimeout);
            if (hideLoadingTimeout) clearTimeout(hideLoadingTimeout);
        };
    }, [isLoading, debounceDelay, hideDelay]);

    return {showLoadingUI, setIsLoading, isLoading};
}

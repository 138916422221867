import React from "react";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import timeAgo from "../../../utils/timeUtils";

const ContactButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.secondaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`
const DeleteButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.backgroundColor};
    border: 2px solid ${colors.destructive};
    color: ${colors.destructive};
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

const DescriptionContent = styled.p`
    font-family: 'Inter', sans-serif;
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
`

const Timestamp = styled.p`
    font-family: 'Inter', sans-serif;
    color: ${colors.secondaryTextColor};
    font-weight: 400;
`

const TitleContent = styled.h1`
    color: ${colors.textColor};
    text-transform: capitalize;
    font-weight: 700;
    font-size: 50px;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 35px;
        margin: 0;
    }
`

const BreedContent = styled.p`
    color: ${colors.secondaryColor};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 0;
`

const ContentWrapper = styled.section`
    flex-basis: 50%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 20px;
    @media (max-width: 768px) {
        padding: 10px;
        margin-top: 25px;
    }
`

const ReportDescription = ({title, description, timestamp, heading, onContactClick, onDeleteClick, isMyReport}) => {
    return (
        <ContentWrapper>
            <BreedContent>{heading}</BreedContent>
            <div>
                <TitleContent>{title}</TitleContent>
                <Timestamp>
                    {timeAgo(timestamp)}
                </Timestamp>
            </div>
            <DescriptionContent>
                {description}
            </DescriptionContent>
            <br/>
            <ButtonsWrapper>
                <ContactButton
                    onClick={onContactClick}>
                    {t('button_contact')}
                </ContactButton>
                {isMyReport ? (
                    <DeleteButton
                        onClick={onDeleteClick}>
                        {t('delete')}
                    </DeleteButton>
                ) : (<div/>)
                }
            </ButtonsWrapper>
        </ContentWrapper>
    );
};

export default ReportDescription;
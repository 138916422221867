import apiService from '../baseApiService';

const reservationService = {
    reserve: async (listingId, kyc) => {
        return await apiService.post(`/v1/reservations/${listingId}/reserve`, kyc);
    },
    getMyReservations: async (status, searchQuery, page, limit) => {
        let queryParams = [];

        if (status !== undefined && status !== null) {
            queryParams.push(`status=${encodeURIComponent(status)}`);
        }
        if (page !== undefined && page !== null) {
            queryParams.push(`page=${encodeURIComponent(page)}`);
        }
        if (limit !== undefined && limit !== null) {
            queryParams.push(`limit=${encodeURIComponent(limit)}`);
        }

        const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
        const url = `/v1/reservations/default/mine${queryString}`;

        return await apiService.get(url);
    },
    getReservation: async (reservationNumber) => {
        return await apiService.get(`/v1/reservations/${reservationNumber}`);
    },
    cancel: async (reservationNumber) => {
        return await apiService.post(`/v1/reservations/${reservationNumber}/cancel`);
    },
};

export default reservationService;

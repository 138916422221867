import styled from "styled-components";
import colors from "../../../assets/styles/colors";

const LokiPrimaryButton = styled.button`
    border-radius: 10px;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.primaryColor};
    color: ${colors.backgroundColor};
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 16px;
    width: 100%;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            opacity: 0.5;
        }
    }
`

export default LokiPrimaryButton


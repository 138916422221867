import i18n from 'i18next';

function formatAge(age) {
    let key;
    if (age < 1) {
        return i18n.t("under_a_year");
    }

    if (i18n.language === 'lt') {
        key = age % 10 === 1 && age % 100 !== 11 ? 'year_singular' : age % 10 >= 2 && (age % 100 < 10 || age % 100 >= 20) ? 'year_few' : 'year_many';
    } else {
        key = age === 1 ? 'age_year' : 'age_years';
    }

    return `${age} ${i18n.t(key, {count: age})}`;
}

export default formatAge;

import styled from "styled-components";
import AutoBreadcrumbs from "../../components/common/Breadcrumbs/Breadcrumbs";
import * as React from "react";
import {useState} from "react";
import {t} from "i18next";
import colors from "../../assets/styles/colors";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LokiPrimaryButton from "../../components/common/Buttons/LokiPrimary";
import supportTicketService from "../../services/firebase/report/supportTicketService";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import {toast} from "react-toastify";
import {getCurrentUserFormatted} from "../../services/firebase/firebaseService";
import {useNavigate} from "react-router-dom";
import BrowserUtil from "../../utils/BrowserUtil";
import Container from "@mui/material/Container";
import NavBar from "../../components/common/NavigationBar/NavBar";

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: ${colors.textColor};
`

export default function SubmitTicketPage() {
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        subject: '',
        description: '',
    });

    const [errors, setErrors] = useState({
        subject: '',
        description: '',
    });

    const validate = () => {
        let tempErrors = {...errors};
        tempErrors.subject = values.subject.trim() ? '' : t('error_field_mandatory');
        tempErrors.description = values.description.trim() ? '' : t('error_field_mandatory');
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const styleFullWidth = {
        bgcolor: 'background.paper',
        width: "100%",
        boxShadow: 0,
        borderRadius: '4px',
        p: 2,
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return
        }

        setLoading(true)

        try {
            let body = {
                subject: values.subject,
                description: values.description,
                status: 'SUBMITTED',
                browser: BrowserUtil.getBrowserReadableType(),
                userAgent: BrowserUtil.getBrowserType(),
                isBrowserMobile: BrowserUtil.isMobile(),
                requester: getCurrentUserFormatted(),
                flavor: 'Default'
            }

            await supportTicketService.submitTicket(body)
            toast.success(t('report_submitted'))
            navigate("/help")
        } catch (e) {
            toast.error(t(e.code || 'general_error'))
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    };

    if (loading) {
        return <LokiLoader/>
    }
    return (
        <>
            <NavBar/>
            <br/>
            <Container maxWidth="md">
                <AutoBreadcrumbs/>
                <br/>
                <br/>
                <Box sx={styleFullWidth}>
                    <SectionTitle>{t('new_support_ticket')}</SectionTitle>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        sx={{background: `${colors.backgroundColor}`, borderRadius: 1}}
                        id="subject"
                        label={t('support_ticket_title_hint')}
                        placeholder={t('support_ticket_title_placeholder')}
                        name="subject"
                        value={values.subject}
                        onChange={handleChange}
                        error={!!errors.subject}
                        helperText={errors.subject}
                        inputProps={{
                            maxLength: 100,
                        }}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        sx={{background: `${colors.backgroundColor}`, borderRadius: 1, mb: 6}}
                        id="description"
                        label={t('support_ticket_message_hint')}
                        placeholder={t('support_ticket_message_placeholder')}
                        multiline
                        rows={6}
                        inputProps={{
                            maxLength: 1000,
                        }}
                        value={values.description}
                        onChange={handleChange}
                        error={!!errors.description}
                        helperText={errors.description}
                        name="description"
                    />
                    <LokiPrimaryButton onClick={handleSubmit}>{t('submit_ticket')}</LokiPrimaryButton>
                </Box>
            </Container>
        </>
    )
}
import {initializeApp} from 'firebase/app';
import {
    createUserWithEmailAndPassword,
    getAuth,
    getIdToken,
    getIdTokenResult,
    GoogleAuthProvider,
    linkWithPhoneNumber,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile
} from 'firebase/auth';
import {addDoc, collection, getDocs, getFirestore, query, serverTimestamp, where} from "firebase/firestore";
import {getDownloadURL, getMetadata, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {toast} from "react-toastify";
import {t} from "i18next";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account "
});

const signInWithGooglePopup = () => signInWithPopup(auth, provider)

const updateDisplayName = async (newDisplayName) => {
    let user = auth.currentUser;

    if (user) {
        try {
            await updateProfile(user, {
                displayName: newDisplayName
            });
        } catch (e) {
            const error = new Error();
            error.code = "R1";

            toast.error(t(error.code || 'general_system_error'))
            throw error;
        }
    }
};

const getCurrentUserIdToken = async (forceRefresh = false) => {
    const user = auth.currentUser;
    if (user) {
        return await getIdToken(user, forceRefresh);
    }
};

const reload = () => {
    const user = auth.currentUser;
    if (user) {
        return user.reload()
    }
}

const getCurrentUserIdTokenResult = async () => {
    const user = auth.currentUser;
    if (user) {
        return await getIdTokenResult(user);
    }
};

const verifyClaims = async () => {
    const user = auth.currentUser;
    if (!user) {
        const error = new Error();
        error.code = "R1";

        toast.error(t(error.code || 'general_system_error'))
        throw error;
    }

    await getCurrentUserIdToken(true)
    const idTokenResult = await getCurrentUserIdTokenResult();
    if (!idTokenResult.claims.ApplicationFlavor || !idTokenResult.claims.UserId) {
        const error = new Error();
        error.code = "R1";

        toast.error(t(error.code || 'general_system_error'))
        throw error;
    }

    const applicationFlavor = process.env.REACT_APP_APPLICATION_FLAVOR;
    if (idTokenResult.claims.ApplicationFlavor !== applicationFlavor) {
        const error = new Error();
        error.code = "R1";

        toast.error(t(error.code || 'general_system_error'))
        throw error;
    }
}

const sendVerificationEmail = async () => {
    if (auth.currentUser == null) {
        toast.error(t('general_error'))
        return false
    }

    try {
        await sendEmailVerification(auth.currentUser)
        toast.success(t('email_verification_sent'))
        return true
    } catch (e) {
        toast.error(t(e.code || 'general_error'))
        return false
    }
}

const linkPhoneNumber = async (phoneNumber, appVerifier) => {
    return await linkWithPhoneNumber(auth.currentUser, phoneNumber, appVerifier)
}

const getUserPhoneNumber = () => {
    if (auth.currentUser == null) {
        return null
    }

    return auth.currentUser.phoneNumber
}

const getCurrentUserFormatted = () => {
    let user = auth.currentUser
    if (user == null) {
        toast.error(t('general_error'))
        return false
    }

    return {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        phoneNumber: user.phoneNumber,
        provider: user.providerId
    }
}

const addDocumentToCollection = async (type, collectionName, documentData) => {
    if (await isWithinRateLimit(type)) {
        const userId = auth.currentUser.uid;
        documentData.createdAt = serverTimestamp()
        const docRef = await addDoc(collection(firestore, collectionName), documentData);
        const submissionRecord = {
            userId,
            type: type,
            createdAt: serverTimestamp()
        }
        await addDoc(collection(firestore, submissionTrackingCollection), submissionRecord);
        return docRef;
    } else {
        throw new Error("firebase/limit_exceeded");
    }
};

let submissionTrackingCollection = "submission-tracking";
const isWithinRateLimit = async (type) => {
    let user = auth.currentUser
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - (60 * 60 * 1000));

    const q = query(collection(firestore, submissionTrackingCollection),
        where("userId", "==", user.uid),
        where("type", "==", type),
        where("createdAt", ">", oneHourAgo)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size < 5;
};

const getCurrentUserUID = () => {
    let user = auth.currentUser
    if (user == null) {
        toast.error(t('general_error'))
        return false
    }

    return user.uid
}

const uploadImageToFirebase = async (file, folder) => {
    if (!file) return;

    const acceptedTypes = ['image/png', 'image/jpeg', 'image/heic'];
    if (!acceptedTypes.includes(file.type)) {
        throw new Error('file_type_unsupported');
    }

    const storageRef = ref(storage, `${folder}/${getCurrentUserUID()}/${generateUUID()}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            () => {
            },
            reject,
            () => resolve(uploadTask.snapshot)
        );
    });

    const url = await getDownloadURL(uploadTask.snapshot.ref);
    return removeTokenFromUrl(url)
}

function removeTokenFromUrl(url) {
    let parsedUrl = new URL(url);
    parsedUrl.searchParams.delete('token');
    return parsedUrl.toString();
}

function generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-mixed-operators
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

async function sendResetEmail(email) {
    return await sendPasswordResetEmail(auth, email);
}

const updateProfileAvatar = async (newAvatarUrl) => {
    let user = auth.currentUser;

    if (user) {
        try {
            await updateProfile(user, {
                photoURL: newAvatarUrl
            });
        } catch (error) {
            throw error
        }
    }
};

const getFileFromFirebase = async (location) => {
    if (!location) return;

    const gsReference = ref(storage, location);
    const metadata = await getMetadata(gsReference);
    const downloadUrl = await getDownloadURL(gsReference)

    return {metadata: metadata, downloadUrl: downloadUrl};
}

async function getCollection(collectionName) {
    try {
        const querySnapshot = await getDocs(collection(firestore, collectionName));
        const items = [];
        querySnapshot.forEach(doc => {
            items.push({id: doc.id, ...doc.data()});
        });
        return items;
    } catch (e) {
        toast.error(t(e.code || 'general_error'))
    }
}

const getUid = () => {
    let uid = localStorage.getItem('gDadspxSxy');
    if (!uid && auth.currentUser) {
        uid = auth.currentUser.uid;
        localStorage.setItem('gDadspxSxy', uid);
    }
    return uid || "null";
};

const clearUidCache = () => {
    localStorage.removeItem('cachedUid');
};

const logout = async () => {
    clearUidCache()
    await signOut(auth)
}

export {
    auth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    logout,
    getCurrentUserIdToken,
    updateDisplayName,
    sendVerificationEmail,
    linkPhoneNumber,
    getUserPhoneNumber,
    getCurrentUserFormatted,
    addDocumentToCollection,
    isWithinRateLimit,
    uploadImageToFirebase,
    sendResetEmail,
    updateProfileAvatar,
    getFileFromFirebase,
    getCollection,
    verifyClaims,
    getCurrentUserIdTokenResult,
    getUid,
    signInWithGooglePopup,
    reload
};


import React from "react";
import {ListingDataWrapper, ListingTitle} from "../Listing/Listing";
import styled from "styled-components";
import {useNavigate} from 'react-router-dom';

const FeaturedThumbnail = styled.img`
    aspect-ratio: 1/1;
    border-radius: 12px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
`

const HoverWrapper = styled.div`
    cursor: pointer;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;

    &:hover {
        opacity: 0.5;
    }
`
export const Subtitle = styled.p`
    max-height: 2em;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    text-overflow: ellipsis; 
    white-space: nowrap;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0px;
    padding-left: 1px;
`

const FeaturedItem = ({item}) => {
    let navigate = useNavigate();

    function handleClick() {
        if (!item.listingId) {
            window.open(item.redirectUrl, "_blank")
            return
        }

        navigate(`/listing/${item.listingId}`);
    }

    const {title, subtitle, thumbnailUrl} = item || {};
    return (
        <HoverWrapper onClick={handleClick}>
            <FeaturedThumbnail src={thumbnailUrl}/>
            <ListingDataWrapper>
                <ListingTitle>{title}</ListingTitle>
                <Subtitle>{subtitle}</Subtitle>
            </ListingDataWrapper>
        </HoverWrapper>
    )
}

export default FeaturedItem
import React from 'react';
import {Divider, ListItemIcon, MenuItem} from "@mui/material";
import {Add, HelpCenter, Logout, Person} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import useAuth from "../../../hooks/useAuth";

const UserDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 16px;
    margin-bottom: 16px;
`
const Username = styled.h6`
    width: 100%;
    text-align: start;
    margin: 0;
`


const Email = styled.p`
    margin: 0;
    width: 100%;
    text-align: start;
    color: ${colors.secondaryTextColor};
`
const UserMenuItems = ({handleClose, onLogout}) => {
    const navigate = useNavigate();
    const {user} = useAuth()

    const handleNavigation = (path) => {
        handleClose();
        navigate(path);
    };

    return (
        <>
            <UserDataWrapper>
                <Username>{user.displayName || ""}</Username>
                <Email>{user.email || ""}</Email>
            </UserDataWrapper>
            <MenuItem onClick={() => handleNavigation("/profile")} sx={{p: '12px'}}>
                <ListItemIcon>
                    <Person/>
                </ListItemIcon>
                {t('my_profile')}
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/new-report")} sx={{p: '12px'}}>
                <ListItemIcon>
                    <Add/>
                </ListItemIcon>
                {t('create_report')}
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleNavigation("/help")} sx={{p: '12px'}}>
                <ListItemIcon>
                    <HelpCenter fontSize="small"/>
                </ListItemIcon>
                {t('help')}
            </MenuItem>
            <MenuItem onClick={onLogout} sx={{p: '12px'}}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                {t('logout')}
            </MenuItem>
        </>
    )
        ;
};

export default React.memo(UserMenuItems);

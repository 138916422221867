import apiService from '../baseApiService';

const profileService = {
    getMyProfile: async () => {
            return await apiService.get(`/v1/profiles/mine`);
    },
    getProfile: async (profileId) => {
            return await apiService.get(`/v1/profiles/${profileId}`);
    },
    upsertKyc: async (kycData) => {
            return await apiService.patch(`/v1/profiles/mine/kyc`, kycData);
    },
    getMyKyc: async () => {
        return await apiService.get(`/v1/profiles/mine/kyc`);
    },
    editProfile: async (requestBody) => {
        return await apiService.patch("/v1/profiles/mine", requestBody)
    }
};

export default profileService;

import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";
import {t} from "i18next";

const Navbar = () => {
    const {isAuthenticated, logout, isLoading} = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            toast.info(t('logout_toast'));
        } catch (error) {
            toast.error(t(error.code || 'general_error'));
        }
    };

    const toggle = () => setIsOpen(!isOpen);
    const isDesktop = useMediaQuery({minWidth: 769});

    return (
        <>
            {isDesktop ? (
                <DesktopNavbar
                    isLoading={isLoading()}
                    isAuthenticated={isAuthenticated()}
                    handleClick={handleClick}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    open={open}
                    handleLogout={handleLogout}
                />
            ) : (
                <MobileNavbar isOpen={isOpen} toggle={toggle}
                              isLoading={isLoading()}
                />
            )}
        </>
    );
};

export default Navbar;

import SearchBar from "./SearchBar";
import {t} from "i18next";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";


const FilterExpandingWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 6px;
    align-items: center;
    justify-content: space-between;
`

const FilterTitle = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
`

const FilterHeading = styled.h2`
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`

const Selector = styled.select`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: 0.7rem;
    width: 100%;
    text-align: left;
    border-radius: 8px;
    margin-bottom: 6px;
    background: ${colors.searchBackground};
`

const SearchButton = styled.button`
    border-radius: 8px;
    width: 100%;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px 16px;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            background: ${colors.primaryColor};
        }
    }
`

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 16px;
    margin-top: 16px;
    gap: 10px;
`
/* eslint-disable eqeqeq */
const ShelterFilter = ({cities, filters, setFilters, applyFilter, onSearchClick}) => {
    const [filterExpanded, setFilterExpanded] = useState(window.innerWidth > 900);

    function toggleFilterExpand() {
        setFilterExpanded(!filterExpanded);
    }

    const handleCitySelect = (event) => {
        const selection = event.target.value
        const newValues = {...filters, cityId: selection};
        setFilters(newValues)
    }

    const handleSearchQueryChange = (event) => {
        const query = event.target.value
        const newValues = {...filters, searchQuery: query};
        setFilters(newValues)
    }


    return (
        <FilterWrapper>
            <SearchBar value={filters.searchQuery} onChange={handleSearchQueryChange} hint="shelter_search_hint"/>
            <SearchButton onClick={onSearchClick}>{t('search')}</SearchButton>

            <FilterExpandingWrapper style={{marginTop: "1.2rem"}}>
                <FilterHeading onClick={toggleFilterExpand}>{t('filters')}</FilterHeading>
                {filterExpanded ?
                    (<ExpandLess
                        onClick={toggleFilterExpand}
                        aria-expanded={filterExpanded}
                        aria-label="show less"
                        sx={{marginBottom: 1, cursor: 'pointer'}}
                    />) :
                    (<ExpandMore
                        onClick={toggleFilterExpand}
                        aria-expanded={filterExpanded}
                        aria-label="show more"
                        sx={{marginBottom: 1, cursor: 'pointer'}}
                    />)}
            </FilterExpandingWrapper>

            <Collapse sx={{width: "100%"}} in={filterExpanded} timeout="auto" unmountOnExit>
                <FilterTitle>{t('filter_city')}</FilterTitle>
                <Selector value={filters.cityId} onChange={handleCitySelect}>
                    <option value={-1} key={-1}>{t('any_city')}</option>
                    {cities.map((city) => <option value={city.id}
                                                  key={city.id}>{t(city.localizationKey)}</option>)}
                </Selector>
                <SearchButton style={{marginTop: '0.5rem'}} onClick={applyFilter}>{t('apply_filter')}</SearchButton>
            </Collapse>
        </FilterWrapper>
    );
}
/* eslint-enable eqeqeq */
export default ShelterFilter
import styled from "styled-components";
import GoogleIcon from "../../../assets/images/icons/google-icon-logo.svg"
import {t} from "i18next";
import colors from "../../../assets/styles/colors";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    height: 52px;
    background: white;
    border-radius: 12px;
    border: 1px solid ${colors.secondaryColor};
    cursor: pointer;
    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            opacity: 0.5;
        }
    }
`
const Icon = styled.img`
    height: 28px;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
`

const Text = styled.p`
    color: ${colors.textColor};
    text-align: start;
    font-weight: 500;
    margin: 0;
`

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 70%;
`

const GoogleButton = ({onClick}) => {
    return (
        <Wrapper onClick={onClick}>
            <IconWrapper>
                <Icon loading="eager" src={GoogleIcon}/>
            </IconWrapper>
            <TextWrapper>
                <Text>{t('continue_with_google')}</Text>
            </TextWrapper>
        </Wrapper>
    )
}

export default GoogleButton
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import React from "react";
import {ListingDataWrapper, ListingSubtitle, ListingThumbnail, ListingTitle} from "../../Listing/Listing";
import styled from "styled-components";

const HoverWrapper = styled.div`
    width: 100%;
    
    @media screen and (min-width: 768px) {
        -webkit-transition: opacity 0.3s ease-in-out;
        -moz-transition: opacity 0.3s ease-in-out;
        -ms-transition: opacity 0.3s ease-in-out;
        -o-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }
    }
`
const ListingResultItem = ({listingSearchResult}) => {
    let navigate = useNavigate();
    const {id, pet: {thumbnails} = {}, title, localizedDescription} = listingSearchResult || {};
    const thumbnail = thumbnails ? thumbnails[0] : undefined;
    function handleClick() {
        navigate(`/listing/${id}`);
    }

    return (
        <HoverWrapper onClick={handleClick}>
            {thumbnail && <ListingThumbnail src={thumbnail}/>}
            <ListingDataWrapper>
                <ListingTitle>{title}</ListingTitle>
                <ListingSubtitle>{t(localizedDescription)}</ListingSubtitle>
            </ListingDataWrapper>
        </HoverWrapper>
    )
}

export default ListingResultItem
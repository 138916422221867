class BrowserUtil {
    static getBrowserReadableType() {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            return "Chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            return "Firefox";
        } else if (userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)) {
            return "Safari";
        } else if (userAgent.match(/edg/i)) {
            return "Edge";
        } else if (userAgent.match(/opr\//i)) {
            return "Opera";
        } else if (userAgent.match(/msie|trident/i)) {
            return "Internet Explorer";
        } else {
            return "Unknown";
        }
    }

    static getBrowserType() {
        return navigator.userAgent;
    }

    static isMobile() {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
}

export default BrowserUtil;

import styled from "styled-components";
import colors from "../../../assets/styles/colors";

const LokiSecondaryRoundedButton = styled.button`
    border-radius: 12px;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.secondaryColor};
    color: ${colors.backgroundColor};
    font-size: 1.05rem;
    font-weight: 500;
    padding: 13px 16px;
    width: 100%;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            opacity: 0.5;
        }
    }
`

export default LokiSecondaryRoundedButton


import React, {useEffect, useState} from 'react';
import LokiPrimaryButton from "../../common/Buttons/LokiPrimary";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";

const HighlightedSpan = styled.span`
    color: ${colors.primaryColor};
    cursor: pointer;
`
const CookieConsent = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent !== 'accepted') {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShowPopup(false);
    };

    if (!showPopup) return null;

    return (
        <div style={{
            display:"flex",
            flexDirection:"column",
            position: 'fixed',
            alignItems:"flex-end",
            bottom: '20px',
            right: '20px',
            backgroundColor: 'white',
            marginLeft:'16px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 20px 26px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
        }}>
            <h4 style={{textAlign:'left', width:'100%'}}>{t('cookie_consent')}</h4>
            <p>{t('cookie_consent_popup')} <br/>{t('cookie_consent_see_privacy_policy')} <HighlightedSpan>{t('cookie_consent_privacy_policy')}</HighlightedSpan>.</p>
            <LokiPrimaryButton onClick={handleAccept} style={{
                padding: '10px 20px',
                width:'30%',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
            }}>
                {t('agree')}
            </LokiPrimaryButton>
        </div>
    );
};

export default CookieConsent;

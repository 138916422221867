import React from "react";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../../assets/styles/colors";

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const Text = styled.h3`
    font-size: 1.4rem;
    padding-top: 2rem;
`
const ResetButton = styled.button`
    margin-left: 10px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 1rem;
    font-weight: 500;
    padding: 8px 16px;
    margin-top: 16px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${colors.primaryColor};
    }
`
const NoResults = ({onResetClick, hasFilters}) => {
    return(
        <Centered>
            <Text>{t('search_no_results')}</Text>
            {hasFilters ? <ResetButton onClick={onResetClick}>{t('reset_filters')}</ResetButton> : <div/>}
        </Centered>
    );
}

export default NoResults
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import React from "react";
import ProfileResultItem from "./ListItems/ProfileResultItem";
import ListingResultItem from "./ListItems/ListingResultItem";
import {Fade} from "@mui/material";

const SectionWrapper = styled.div`
    margin-left: 2rem;
    margin-right: 2rem;
    width: 100%;

    @media screen and (max-width: 1000px) {
        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 2rem;
    }
`


const SearchResultsGroup = ({listings, shelters}) => {
    if (listings) {
        return (
            <SectionWrapper>
                <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                    {listings.map((listing, index) => (
                        <Grid key={index} item xs={12} sm={4} md={3} lg={2.4}>
                            <ListingResultItem listingSearchResult={listing}/>
                        </Grid>
                    ))}
                </Grid>
            </SectionWrapper>
        );
    }

    if (shelters) {
        return (
            <Fade in={true} timeout={300}>
                <SectionWrapper>
                    <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                        {shelters.map((shelter) => (
                            <Grid key={shelter.profileId} item xs={12} sm={4} md={3} lg={2.4}>
                                <ProfileResultItem shelterSearchResult={shelter}/>
                            </Grid>
                        ))}
                    </Grid>
                </SectionWrapper>
            </Fade>
        );
    }
}

export default SearchResultsGroup
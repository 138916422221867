import {addDocumentToCollection} from "../firebaseService";

let supportCollection = "support-tickets"
let bugCollection = "reported-bugs"
let ideaCollection = "ideas"

const supportTicketService = {
    submitTicket: async (ticket) => {
        return addDocumentToCollection('ticket', supportCollection, ticket)
    },

    submitBugReport: async (report) => {
        return addDocumentToCollection('bug', bugCollection, report)
    },

    submitIdea: async (idea) => {
        return addDocumentToCollection('idea', ideaCollection, idea)
    }

};

export default supportTicketService;

import apiService from '../baseApiService';

const sessionService = {

    registerSession: async (sessionCreateDto) => {
        return await apiService.post(`/v1/sessions/register`, sessionCreateDto)
    },
    loginSession: async (sessionLoginDto) => {
        return await apiService.post(`/v1/sessions/login`, sessionLoginDto)
    },
};

export default sessionService;

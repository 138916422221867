import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from "@mui/material";
import {useLocation} from "react-router-dom";
import {t} from "i18next";

const AutoBreadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
            <Link
                underline="hover"
                key="1"
                color="inherit"
                href="/">
                {t('appName')}
            </Link>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                    <Typography color="text.primary" key={to}>
                        {t(value)}
                    </Typography>
                ) : (
                    <Link underline="hover"
                          color="inherit"
                          href={to}
                          key={to}>
                        {t(value)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default AutoBreadcrumbs
